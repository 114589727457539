import { PersonProgressing } from "../../types";

import { PersonPreview } from "./PersonPreview";
import { ProgressionModal } from "./ProgressionModal";

import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";
import { EmptyList, ItemCardList } from "@hire/components/form/ItemCardList";
import { useUnifiedOfferFeatures } from "@hire/util/hooks";
import { Upsell } from "@hire/pages/CompanyProfile/CompanyDetails/Upsell";
import { Spacing } from "@otta/design";

interface ProgressionSectionProps {
  people: PersonProgressing[];
  onChange: (updatedPeople: PersonProgressing[]) => void;
  companyName: string;
}

export const ProgressionSection = ({
  people,
  companyName,
  onChange,
}: ProgressionSectionProps) => {
  const { peopleProgressingFeature } = useUnifiedOfferFeatures();
  return (
    <MoreInfoFieldWrapper
      label="People progressing"
      fieldName="peopleProgressing"
      guidance="We recommend up to 2 people who have been promoted since they joined. This helps candidates see they can progress and grow at your company."
      optional
      featureGranted={peopleProgressingFeature.granted}
    >
      <Spacing>
        {peopleProgressingFeature.showUpsell && (
          <Upsell
            title="Showcase progression successes"
            listItems={[
              "Add up to 2 stories of colleagues who have been promoted since they joined",
              `These examples show candidates how they can progress and grow at ${companyName}`,
            ]}
          />
        )}
        <ItemCardList
          data={people}
          name="employee"
          renderForm={props => <ProgressionModal {...props} />}
          renderPreview={person => <PersonPreview {...person} />}
          renderEmpty={() => (
            <EmptyList icon="user" text="Add employees to your profile" />
          )}
          onChange={onChange}
          featureGranted={peopleProgressingFeature.granted}
        />
      </Spacing>
    </MoreInfoFieldWrapper>
  );
};
