import { Measure } from "./Measure";

import { DashboardFragment } from "@hire/schema";
import { Link } from "@hire/components/links/Link";
import { Salary } from "@hire/components/icons";

interface CandidateExperienceMeasuresProps {
  currentCompany: DashboardFragment;
}

interface Status {
  quadrant: 0 | 1 | 2 | 3 | 4;
  description: string;
  tip: string;
}

const getSalaryStatus = ({
  numberLiveJobs,
  numberLiveJobsWithSalary,
  numberLiveJobsWithPublicSalary,
}: {
  numberLiveJobs: number;
  numberLiveJobsWithSalary: number;
  numberLiveJobsWithPublicSalary: number;
}): Status => {
  if (numberLiveJobs === 0) {
    return {
      quadrant: 0,
      description: "No published jobs",
      tip: "We'll let you know if you need to add salaries.",
    };
  } else if (numberLiveJobsWithSalary === 0) {
    return {
      quadrant: 1,
      description: "None of your jobs have salaries",
      tip: "Public salaries are twice as effective as hidden salaries.",
    };
  } else if (numberLiveJobsWithSalary !== numberLiveJobs) {
    return {
      quadrant: 2,
      description: "Some of your jobs have salaries",
      tip: "Public salaries are twice as effective as hidden salaries.",
    };
  } else if (numberLiveJobsWithSalary !== numberLiveJobsWithPublicSalary) {
    return {
      quadrant: 3,
      description: "Make all of your salaries public",
      tip: "Public salaries are twice as effective as hidden salaries.",
    };
  } else {
    return {
      quadrant: 4,
      description: "All your jobs have public salaries",
      tip: "We'll let you know if you need to add salaries.",
    };
  }
};

export const CertifiedOverview = ({
  currentCompany,
}: CandidateExperienceMeasuresProps): React.ReactElement => {
  const { jobsWithSalaryStats } = currentCompany;

  const salaryStatus = getSalaryStatus(jobsWithSalaryStats);

  return (
    <Link
      to="./jobs"
      underline={false}
      data-analytics-id="dashboard-jobs-with-salaries"
    >
      <Measure
        title="Jobs with salaries"
        description={salaryStatus.description}
        tip={salaryStatus.tip}
        fillQuandrant={salaryStatus.quadrant}
        icon={Salary}
        dataTestId="jobs-with-salaries"
      />
    </Link>
  );
};
