import styled from "@xstyled/styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { format, parseISO } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";

import { JobSlotsModal } from "../JobSlotsModal";

import {
  Button,
  Text,
  Card,
  List,
  Spacing,
  TypographyProps,
} from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import {
  AddJobToSubscriptionDocument,
  AmendmentQuoteDocument,
  CatalogueProductId,
  Currency,
  JobInfoDocument,
  JobInfoQuery,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { formatCurrency } from "@hire/util/currencies";
import { useCurrentUser } from "@hire/hooks/useCurrentUser";

const ButtonContainer = styled.div`
  margin-top: xl;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1em;
`;

const StyledButton = styled(Button)`
  width: 50%;
`;

const LineItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 0.1rem solid ${palette.grayscale.shade200};
  padding: 0.5rem 0;

  &:first-of-type {
    margin-top: 0.5rem;
    border-top: none;
  }

  &:last-of-type {
    border-top: 0.1rem solid ${palette.brand.black};
    font-weight: 600;
  }
`;

function CurrencyText({
  amount,
  currency,
  showPlus = false,
  ...props
}: {
  amount: string;
  currency: Currency;
  showPlus?: boolean;
} & TypographyProps): React.ReactElement {
  return (
    <Text {...props}>{`${showPlus ? "+ " : ""}${formatCurrency(
      amount,
      currency
    )}`}</Text>
  );
}

export function UnfreezeYourRole() {
  const { jobExternalId } = useParams();
  const { data: jobData, loading: jobLoading } = useQuery(JobInfoDocument, {
    variables: { jobId: jobExternalId as string },
  });

  if (!jobExternalId || jobLoading || !jobData?.companyJob) {
    return null;
  }

  return <UnfreezeYourRoleComponent job={jobData.companyJob} />;
}

const UnfreezeYourRoleComponent = ({
  job,
}: {
  job: NonNullable<JobInfoQuery["companyJob"]>;
}) => {
  const [user, { loading: jobSlotsLoading }] = useCurrentUser();
  const paidJobSlots = user?.currentCompany?.jobSlotData?.paid ?? 1;

  const { data, loading } = useQuery(AmendmentQuoteDocument, {
    variables: { productId: CatalogueProductId.JobSlotMonthly, quantity: 1 },
    fetchPolicy: "no-cache",
  });

  const navigate = useNavigate();
  const { companySlug } = useParams<"companySlug">();

  const [addJobToCompanySubscription, { loading: paymentLoading }] =
    useMutation(AddJobToSubscriptionDocument, {
      variables: {
        jobIds: [job.id],
      },
      onCompleted() {
        navigate(
          `/${companySlug}/checkout/job-slot-confirmation/${job.externalId}`
        );
      },
    });

  const currentCompanySubscription =
    data?.currentUser?.currentCompany?.activeSubscriptions[0];

  const amendmentQuote = currentCompanySubscription?.amendmentQuote;

  useEffect(() => {
    if (loading || jobSlotsLoading) {
      return;
    }
    if (!amendmentQuote) {
      window.alert("Something went wrong. Try again later.");
      navigate(`/${companySlug}/jobs`);
      return;
    }

    if (
      !amendmentQuote.nextChargeDate ||
      !amendmentQuote.priceIncrease ||
      !amendmentQuote.nextChargePrice
    ) {
      navigate(`/${companySlug}/jobs/recently-cancelled`);
    }
  }, [navigate, amendmentQuote, companySlug, loading, jobSlotsLoading]);

  if (loading || jobSlotsLoading) {
    return <Loading />;
  }

  if (!amendmentQuote) {
    return null;
  }

  if (
    !amendmentQuote.nextChargeDate ||
    !amendmentQuote.priceIncrease ||
    !amendmentQuote.nextChargePrice
  ) {
    return null;
  }

  return (
    <JobSlotsModal
      name="unfreeze-your-role"
      redirectOnClose={`/${companySlug}/jobs`}
    >
      <Text size={2} bold style={{ marginBottom: pxToRem(24) }}>
        {`Publish your ${job.title} role`}
      </Text>
      <Spacing size={2}>
        <Card style={{ backgroundColor: palette.beige.shade200 }}>
          <LineItem>
            {`Existing price per month for ${paidJobSlots} job slot${
              paidJobSlots > 1 ? "s" : ""
            }`}
            <CurrencyText
              amount={currentCompanySubscription.price.net}
              currency={currentCompanySubscription.price.currency}
            />
          </LineItem>
          <LineItem>
            This job slot
            <CurrencyText
              amount={amendmentQuote.priceIncrease.net}
              currency={amendmentQuote.priceIncrease.currency}
              showPlus
            />
          </LineItem>
          <LineItem>
            New price per month (excluding VAT)
            <CurrencyText
              bold
              amount={amendmentQuote.nextChargePrice.net}
              currency={amendmentQuote.nextChargePrice.currency}
            />
          </LineItem>
        </Card>
        <List type="positive" data-testid="unfreeze-your-role-item-list">
          <li key="pay-today-item">Pay today to start hiring immediately.</li>
          <li key="no-commitment-item">
            No commitment, cancel online anytime.
          </li>
          <li key="reuse-this-job-slot-item">
            Reuse this job slot for other jobs.
          </li>
          <li>
            Subscription will continue until explicitly cancelled, which can be
            done online.
          </li>
        </List>
        <Text size={-1} data-testid="disclaimer-text">
          {`When you click 'Pay now', the payment card linked to this
          plan will be charged a prorated amount of ${formatCurrency(
            amendmentQuote.proRataAdjustment.net,
            amendmentQuote.proRataAdjustment.currency
          )} for the remainder of this
          billing cycle. Your new monthly price will take effect from ${format(
            parseISO(amendmentQuote.nextChargeDate),
            "d MMMM"
          )}.`}
        </Text>
      </Spacing>
      <ButtonContainer>
        <StyledButton
          disabled={paymentLoading}
          level="primary"
          onClick={() => addJobToCompanySubscription()}
        >
          {paymentLoading ? "Loading" : "Pay now"}
        </StyledButton>
        <StyledButton
          level="destructive"
          as={Link}
          to="../why-otta"
          state={{ redirect: `../unfreeze-your-role/${job.externalId}` }}
        >
          Maybe later
        </StyledButton>
      </ButtonContainer>
    </JobSlotsModal>
  );
};
