import styled, { x, up, css } from "@xstyled/styled-components";

import { Card, Button, Text } from "@otta/design";
import { Icon } from "@otta/icons";

const Container = styled(Card)`
  border: 1px solid;
  border-color: gray-200;
  padding: xl;
  width: 100%;
  text-align: left;

  ${up(
    "tablet",
    css`
      padding: md;
    `
  )}
`;

export const Upsell = ({
  image = "https://static.otta.com/hire/company-profile-upsell-graphic.png",
  href = "https://hire.welcometothejungle.com/book-a-call",
  title,
  listItems,
}: {
  image?: string;
  href?: string;
  title: string;
  listItems: string[];
}) => {
  return (
    <Container>
      <x.div display="flex" gap="xl" alignItems="flex-start">
        <x.img
          display={{ _: "none", tablet: "block" }}
          w={399}
          maxW="33%"
          src={image}
        />
        <x.div
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          gap="lg"
        >
          <x.div display="flex" flexDirection="column" gap="lg">
            <Text size={1} bold>
              {title}
            </Text>
            <x.ul
              display="flex"
              flexDirection="column"
              gap="lg"
              listStyleType="none"
            >
              {listItems.map((li, idx) => (
                <x.li display="flex" alignItems="center" gap="sm" key={idx}>
                  <Icon icon="checkmark" />
                  <Text>{li}</Text>
                </x.li>
              ))}
            </x.ul>
          </x.div>
          <Button
            as="a"
            level="primary"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            data-analyticsid="company-edit-form-upsell"
          >
            Book a call
          </Button>
        </x.div>
      </x.div>
    </Container>
  );
};
