export const disapprovalReasonOptions = [
  {
    label: "Location",
    value: "LOCATION",
  },
  {
    label: "Unsupported location",
    value: "AUTOMATED_LOCATION",
  },
  {
    label: "Experience",
    value: "EXPERIENCE",
  },
  {
    label: "Requirements",
    value: "REQUIREMENTS",
  },
  {
    label: "Language",
    value: "LANGUAGE",
  },
  {
    label: "Duplicate job",
    value: "DUPLICATE_JOB",
  },
  {
    label: "Unsupported function",
    value: "UNSUPPORTED_FUNCTION",
  },
  {
    label: "Lacks information",
    value: "LACKS_INFORMATION",
  },
  {
    label: "Unsupported role type",
    value: "UNSUPPORTED_ROLE_TYPE",
  },
  {
    label: "Job not live",
    value: "JOB_NOT_LIVE",
  },
];
