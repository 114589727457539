import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import { SourcingPreferencesFieldProps } from "./interfaces";

import { AllSubFunctionsDocument } from "@hire/schema";
import { Icon } from "@otta/icons";
import { VerticalSpacing, Text, Label, SelectField } from "@otta/design";
import { mapQueryResultToSelectOption } from "@hire/util/select";
import { alphabeticalSortByKey } from "@hire/util/collections";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";

export function JobSubFunctionIdsField({
  preferences,
  onChange,
}: SourcingPreferencesFieldProps): React.ReactElement {
  const { data: allJobFunctionsAndSubFunctions } = useQuery(
    AllSubFunctionsDocument
  );
  const { allSubFunctionOptions } = useMemo(() => {
    const options = (allJobFunctionsAndSubFunctions?.jobFunctions ?? [])
      .map(jobFunction => {
        return jobFunction.subFunctions;
      })
      .flat()
      .map(mapQueryResultToSelectOption("value"))
      .sort(alphabeticalSortByKey("label"));
    return { allSubFunctionOptions: options };
  }, [allJobFunctionsAndSubFunctions]);

  return (
    <VerticalSpacing size={-4}>
      <TooltipWithIcon
        content="Select additional role categories to increase the pool of relevant candidates"
        icon={<Icon icon="circle-info" size={1} />}
      >
        <Label htmlFor="jobSubFunctionIds">
          <Text bold>Relevant role categories</Text>
        </Label>
      </TooltipWithIcon>
      <SelectField
        isMulti
        options={allSubFunctionOptions}
        value={allSubFunctionOptions.filter(({ value }) =>
          (preferences.jobSubFunctionIds || []).find(
            (id: string) => id === value
          )
        )}
        onChange={vals => {
          onChange({ jobSubFunctionIds: vals.map(({ value }) => value) });
        }}
      />
    </VerticalSpacing>
  );
}
