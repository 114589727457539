import { useQuery } from "@apollo/client";
import { DragDropContext, DragStart, DropResult } from "@hello-pangea/dnd";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import styled from "@xstyled/styled-components";

import {
  DEFAULT_BULK_STAGE_OPTION,
  getBulkStageOptions,
  handleCompleteBulkMove,
} from "../helpers";
import { StageOption } from "../types";
import { CandidateDrawerContext } from "../Candidate/ImportedCandidateProfile/CandidateDrawerProvider";

import { BulkSelectToast } from "./BulkMessaging/BulkSelectToast";
import { BulkMessageModal, BulkMessageModalRef } from "./BulkMessaging/Modal";
import { PipelineHeader } from "./PipelineHeader";
import { Stage } from "./Stage";
import { ToastMessage } from "./ToastMessage";
import { ModalRef, WarningModal } from "./WarningModal";

import {
  CandidatePipelineItemFragment,
  CandidatePipelineStageFragment,
  KanbanUserDocument,
  StageType,
} from "@hire/schema";
import { VerticalSpacing } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Confetti } from "@hire/components/Confetti";
import { useRequiredParams } from "@hire/util/routing";
import { hireAppUser } from "@hire/util/user";
import { ProUpsellPipelineStage } from "@hire/components/ProUpsell";

const KanbanBoardContainer = styled.div`
  padding: lg;
`;

const KanbanStagesContainer = styled.div`
  padding: 0 lg;
  margin: lg -lg 0;
  display: flex;
  gap: sm;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

function nonNullable<T>(value: T | null | undefined): T {
  if (value == null) {
    throw new Error("Value is null");
  }
  return value;
}

export const KanbanBoard = ({
  pipelineStages,
  candidates,
  move,
  bulkMove,
}: {
  pipelineStages: CandidatePipelineStageFragment[];
  candidates: Map<string, CandidatePipelineItemFragment>;
  move(id: string, stageId: string): void;
  bulkMove(ids: string[], stageId: string): void;
}) => {
  const { setAllCurrentCandidatePipelineItems } = useContext(
    CandidateDrawerContext
  );
  useEffect(() => {
    setAllCurrentCandidatePipelineItems(candidates);
  }, [candidates, setAllCurrentCandidatePipelineItems]);

  const location = useLocation();
  const { jobId: jobExternalId } = useRequiredParams(["jobId"]);

  const hireModalRef = useRef<ModalRef>(null);
  const bulkMessageModalRef = useRef<BulkMessageModalRef>(null);

  const [showConfetti, setShowConfetti] = useState(false);

  const draggableResponse = useRef<DropResult>();

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [selectedBulkStageOption, setSelectedBulkStageOption] = useState(
    DEFAULT_BULK_STAGE_OPTION
  );

  const { data: currentUserData } = useQuery(KanbanUserDocument);
  const currentUser = hireAppUser(currentUserData?.me);
  const company = currentUser?.currentCompany;

  const bulkStageOptions = useMemo(
    () => getBulkStageOptions(pipelineStages),
    [pipelineStages]
  );

  const stageTypeHired = useCallback(
    (newStageId: string) =>
      pipelineStages.find(stage => stage.id === newStageId)?.stageType ===
      StageType.Hired,
    [pipelineStages]
  );

  const handleDragEnd = (results: DropResult) => {
    // cannot drop a candidate into the same column again
    if (
      !results.destination ||
      results.source.droppableId === results.destination?.droppableId
    ) {
      return;
    }

    pushAnalyticsEvent({
      eventName: "Recruiter moved candidate to stage",
      jobId: jobExternalId,
      companyId: company?.externalId,
      toStageType: pipelineStages.find(
        stage => stage.id === results.destination?.droppableId
      )?.stageType,
      method: "Drag-n-drop",
    });

    // if candidate is being droppped into hired,
    // we warn the user to double check if the action was intentional
    const currentItem = candidates.get(results.draggableId);
    const isHiredStage = stageTypeHired(results.destination.droppableId);

    if (isHiredStage && currentItem?.candidate) {
      draggableResponse.current = results;
      hireModalRef.current?.show(currentItem.candidate.firstName);
    } else if (!isHiredStage || currentItem?.importedCandidate) {
      move(results.draggableId, results.destination.droppableId);
    }
  };

  const bulkMoveItems = (
    { label: stageName, value: stageId }: { label: string; value: string },
    hired?: boolean
  ) => {
    bulkMove(selectedItemIds, stageId);
    toggleItemSelection(selectedItemIds);

    setSelectedBulkStageOption(DEFAULT_BULK_STAGE_OPTION);

    if (hired) {
      setShowConfetti(true);
    }

    handleCompleteBulkMove({ candidateIds: selectedItemIds, stageName });
  };

  const handleHireModalClose = (hired: boolean) => {
    if (hired) {
      if (draggableResponse.current) {
        moveCandidate(draggableResponse.current);
        setShowConfetti(true);
      }

      if (selectedItemIds.length) {
        bulkMoveItems(selectedBulkStageOption, true);
      }
    }

    hireModalRef.current?.hide();
    setSelectedBulkStageOption(DEFAULT_BULK_STAGE_OPTION);
    draggableResponse.current = undefined;
  };

  const moveCandidate = async (results: DropResult) => {
    if (results.destination) {
      move(results.draggableId, results.destination.droppableId);

      if (stageTypeHired(results.destination.droppableId)) {
        pushAnalyticsEvent({
          eventName: "Company Recruiter clicked Confirm Hire (Pipeline)",
          jobId: jobExternalId,
          companyId: company?.externalId,
          candidateId: results.draggableId,
        });
      }
    }
  };

  const [disabledStages, setDisabledStages] = useState<StageType>();

  const handleDragStart = (start: DragStart) => {
    const item = candidates.get(start.draggableId);

    const isOttaCandidate = !!item?.candidate;
    const applied = item?.jobApplication ? true : false;

    if (isOttaCandidate && applied) {
      setDisabledStages(StageType.Sourced);
    } else if (isOttaCandidate && !applied) {
      setDisabledStages(StageType.Applied);
    } else {
      setDisabledStages(undefined);
    }
  };

  useEffect(() => {
    if (company) {
      pushAnalyticsEvent({
        eventName: "Company Recruiter viewed Kanban Board",
        jobId: jobExternalId,
        companyId: company?.id,
      });
    }
  }, [jobExternalId, company, location]);

  // Item ids passed to this function are added to the selectedItems array if they are not already present
  // If all items are already present, they are all instead removed from the array
  const toggleItemSelection = (itemIds: string[]) => {
    if (itemIds.every(selected => selectedItemIds.includes(selected))) {
      setSelectedItemIds(selectedItemIds.filter(id => !itemIds.includes(id)));
    } else {
      setSelectedItemIds([
        ...selectedItemIds,
        ...itemIds.filter(id => !selectedItemIds.includes(id)),
      ]);
    }
  };

  const handleBulkMessageClick = () => {
    bulkMessageModalRef.current?.show();
    pushAnalyticsEvent({
      eventName: "Company Recruiter Clicked",
      name: "Message Candidates (Bulk actions popup)",
      jobId: jobExternalId,
      companyId: company?.externalId,
      candidates: selectedItemIds,
    });
  };

  const handleBulkMoveItems = (stageOption: StageOption | null) => {
    if (!stageOption) {
      return;
    }
    setSelectedBulkStageOption(stageOption);
    if (stageTypeHired(stageOption.value)) {
      hireModalRef.current?.show(selectedItemIds.length);
      return;
    }
    bulkMoveItems(stageOption);
  };

  return (
    <>
      <KanbanBoardContainer>
        <VerticalSpacing size={-1}>
          <PipelineHeader
            externalJobId={jobExternalId}
            showMoveDropdown={selectedItemIds.length > 0}
            bulkStageOptions={bulkStageOptions}
            onBulkMoveItems={handleBulkMoveItems}
            selectedBulkStageOption={selectedBulkStageOption}
          />
          <DragDropContext
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <KanbanStagesContainer>
              <ProUpsellPipelineStage
                isPaying={!!company?.isCurrentPayingCustomer}
              />
              {pipelineStages.map(
                stage =>
                  stage.candidatePipelineItems && (
                    <Stage
                      key={stage.id}
                      stage={stage}
                      jobId={jobExternalId}
                      selectedItems={selectedItemIds}
                      toggleItemSelection={toggleItemSelection}
                      disabled={disabledStages === stage.stageType}
                    />
                  )
              )}
            </KanbanStagesContainer>
          </DragDropContext>
        </VerticalSpacing>
      </KanbanBoardContainer>

      <ToastMessage isVisible={selectedItemIds.length > 0}>
        <BulkSelectToast
          selectedItems={selectedItemIds}
          handleBulkMessage={handleBulkMessageClick}
          handleDeselectAll={() => setSelectedItemIds([])}
        />
      </ToastMessage>

      {jobExternalId && (
        <BulkMessageModal
          ref={bulkMessageModalRef}
          stageOptions={bulkStageOptions}
          selectedItems={selectedItemIds.map(id => {
            const candidate = nonNullable(candidates.get(id));
            return {
              candidateId: nonNullable(candidate.candidate).externalId,
              itemId: id,
            };
          })}
          handleSubmit={stageId => {
            if (stageId) {
              bulkMove(selectedItemIds, stageId);
            }
            setSelectedItemIds([]);
          }}
          externalJobId={jobExternalId}
          jobId={jobExternalId}
        />
      )}

      <WarningModal
        ref={hireModalRef}
        onConfirm={() => handleHireModalClose(true)}
        onCancel={() => handleHireModalClose(false)}
      />

      {showConfetti && <Confetti onComplete={() => setShowConfetti(false)} />}
    </>
  );
};
