import { useQuery } from "@apollo/client";
import { Link, Outlet, useParams } from "react-router-dom";
import styled, { x } from "@xstyled/styled-components";
import { useMemo } from "react";

import {
  CompanyProfileCompletionDocument,
  CompanyWorkflowStatus,
} from "@hire/schema";
import { Icon } from "@otta/icons";
import { AttentionGetter } from "@hire/components/AttentionGetter";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { SubNavBar } from "@hire/components/layout/SubNavBar";
import * as Banner from "@hire/components/Banner";
import { Text } from "@otta/design";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

const CompletedByUserBanner = styled(Banner.Warning)`
  max-width: none;
  margin-bottom: md;
`;

export const CompanyProfile = () => {
  const { companySlug } = useParams<"companySlug">();
  const { data, loading } = useQuery(CompanyProfileCompletionDocument);
  const currentCompany = data?.currentUser?.currentCompany;

  const isCompletedByUser =
    currentCompany?.workflowStatus === CompanyWorkflowStatus.CompletedByUser;
  const hasEndorsements =
    currentCompany?.numberOfEndorsements !== undefined &&
    currentCompany.numberOfEndorsements > 0;
  const hasDiversityInitiatives =
    currentCompany?.diversityInitiatives !== undefined &&
    currentCompany.diversityInitiatives.length > 0;

  const sections = useMemo(
    () => [
      {
        links: [
          { content: "Company details", to: "details" },
          { content: "People", to: "people" },
          {
            content: "Diversity, equity and inclusion",
            to: "diversity",
            badge:
              !loading && !hasDiversityInitiatives ? (
                <AttentionGetter />
              ) : undefined,
          },
          { content: "Investors and funding", to: "funding" },
          { content: "Employee benefits", to: "benefits" },
          { content: "External links", to: "links" },
          {
            content: "Endorsements",
            to: "endorsements",
            badge:
              !loading && !hasEndorsements ? <AttentionGetter /> : undefined,
          },
          { content: "Visa sponsorship", to: "visa-sponsorship" },
        ],
      },
      {
        links: [
          {
            content: `View profile`,
            badge: <Icon icon="external" />,
            to: `${
              import.meta.env.VITE_SEARCH_APP_HOST
            }/companies/${companySlug}`,
            target: "_blank",
          },
        ],
      },
    ],
    [companySlug, hasDiversityInitiatives, hasEndorsements, loading]
  );

  return (
    <GridContainer>
      <SubNavBar sections={sections} />
      <ResponsiveCenteredContainer>
        {isCompletedByUser && (
          <CompletedByUserBanner>
            <Text data-testid="completed-by-user-banner">
              Your company profile won't be visible to others until it has been
              reviewed and approved. However,{" "}
              <x.a
                as={Link}
                to="/jobs"
                color="inherit"
                textDecoration="underline"
              >
                you can still add jobs
              </x.a>{" "}
              in the meantime.
            </Text>
          </CompletedByUserBanner>
        )}
        <Outlet />
      </ResponsiveCenteredContainer>
    </GridContainer>
  );
};
