import { SourcingPreferencesFieldProps } from "./interfaces";

import { Icon } from "@otta/icons";
import { VerticalSpacing, Label, Text } from "@otta/design";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";
import { MultiTextInput } from "@hire/components/input/MultiTextInput";

export function CompanyNamesField({
  preferences,
  onChange,
}: SourcingPreferencesFieldProps): React.ReactElement {
  return (
    <VerticalSpacing size={-4}>
      <TooltipWithIcon
        content="See candidates with previous experience at certain companies"
        icon={<Icon icon="circle-info" size={1} />}
      >
        <Label htmlFor="companyNames">
          <Text bold>Companies</Text>
        </Label>
      </TooltipWithIcon>
      <MultiTextInput
        minLength={2}
        value={preferences.companyNames ?? []}
        placeholder="Enter company names"
        onChange={vals => {
          onChange({ companyNames: vals });
        }}
      />
    </VerticalSpacing>
  );
}
