import { Outlet, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import { CandidateHeader } from "./CandidateHeader";
import { updateApplicationResponse } from "./cache";

import {
  CompanyConversationDocument,
  ConversationMessagesDocument,
  CreateCandidateConversationMessageDocument,
  UpdateCompanyJobApplicationDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { useRequiredParams } from "@hire/util/routing";
import { ReloadableError } from "@hire/components/ReloadableError";
import { ConversationMessageInput } from "@hire/pages/JobPage/Pipeline/Candidate/CandidateConversation/ConversationMessageInput";
import { handleMutationError } from "@hire/errors";
import { useCurrentUser } from "@hire/hooks/useCurrentUser";
import { pushAnalyticsEvent } from "@otta/analytics";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";

export function InboxCandidate() {
  const navigate = useNavigate();
  const { jobId, candidateId } = useRequiredParams(["jobId", "candidateId"]);

  const [user, { loading: userLoading }] = useCurrentUser();

  const {
    data: conversationData,
    loading: conversationLoading,
    refetch: refetchConversation,
  } = useQuery(CompanyConversationDocument, {
    variables: {
      jobId,
      candidateId,
    },
  });

  const [updateJobApplication] = useMutation(
    UpdateCompanyJobApplicationDocument,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CompanyConversationDocument,
          variables: { jobId, candidateId },
        },
      ],
      onError: handleMutationError,
      update: updateApplicationResponse,
    }
  );

  const [createMessage] = useMutation(
    CreateCandidateConversationMessageDocument,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CompanyConversationDocument,
          variables: { jobId, candidateId },
        },
        {
          query: ConversationMessagesDocument,
          variables: { jobId, candidateId },
        },
      ],
      onError: handleMutationError,
    }
  );

  const refetch = useCallback(async () => {
    await refetchConversation();
  }, [refetchConversation]);

  if (conversationLoading || userLoading) {
    return <Loading />;
  }

  const conversation = conversationData?.companyRecruiterConversation;

  if (!conversation || !user) {
    return (
      <ResponsiveCenteredContainer>
        <ReloadableError action={refetch} />
      </ResponsiveCenteredContainer>
    );
  }

  const canReply =
    !conversation.sourced ||
    !!conversation.hasAcceptedRequest ||
    !!conversation.jobApplication;

  const isUnrespondedApplication =
    conversation.jobApplication && !conversation.latestMessage;

  return (
    <>
      <CandidateHeader
        candidate={conversation.recipient}
        jobId={conversation.job.id}
        candidateId={candidateId}
        showConversationTab={!isUnrespondedApplication}
        atsProfile={conversationData.candidate?.atsProfile ?? null}
        conversationId={conversation.conversationId}
        archived={conversation.archived}
      />
      <Outlet context={{ conversation }} />
      {canReply && (
        <ConversationMessageInput
          key={`${conversation.job.externalId}:${conversation.recipient.externalId}`}
          jobId={conversation.job.externalId}
          candidateId={conversation.recipient.externalId}
          templates={!!isUnrespondedApplication}
          name={conversation.recipient.firstName}
          companyName={conversation.job.company.name}
          jobTitle={conversation.job.title}
          recruiterFirstName={user?.firstName}
          onSend={async message => {
            pushAnalyticsEvent({
              eventName: "Company Recruiter clicked Send message (Inbox)",
              jobId: conversation.job.externalId,
              companyId: conversation.job.company.id,
              candidateId: conversation.recipient.externalId,
            });

            if (isUnrespondedApplication) {
              await updateJobApplication({
                variables: {
                  id: conversation.jobApplication!.id,
                  input: {
                    message,
                    accepted: true,
                  },
                },
              });

              navigate("conversation");
            } else if (conversation.conversationId) {
              await createMessage({
                variables: {
                  content: message,
                  conversationId: conversation.conversationId,
                },
              });
            }
          }}
        />
      )}
    </>
  );
}
