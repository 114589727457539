import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { useCookie } from "react-use";

import { MarketingConsent } from "./MarketingConsent";
import { TermsConditionsConsent } from "./TermsAndConditionsConsent";

import {
  Button,
  Card,
  ErrorText,
  FieldWrapper,
  Heading,
  InputField,
  PasswordField,
  Spacing,
  Text,
} from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { workEmailRegex } from "@hire/validation/strings";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  CompaniesWithPreApprovalDocument,
  CompaniesWithPreApprovalQuery,
} from "@hire/schema";
import { Link } from "@hire/components/links";
import { extractHost, validateDomain } from "@hire/validation/domain";
import { useAnswers } from "@hire/components/layout/QuizLayout";

const companyFromEmail = (
  data: CompaniesWithPreApprovalQuery | undefined,
  email: string
) => {
  const domain = (email.split("@").pop() ?? "").toLowerCase();
  return companyFromDomain(data, domain);
};

const companyFromDomain = (
  data: CompaniesWithPreApprovalQuery | undefined,
  domain: string
) => {
  return data?.companies.find(
    c => c.websiteUrl?.replace("https://", "")?.toLowerCase() === domain
  );
};

const validationSchema = (data: CompaniesWithPreApprovalQuery | undefined) =>
  Yup.object().shape({
    companyName: Yup.string().required("This field is required"),
    websiteUrl: validateDomain(
      Yup.string().required("This field is required"),
      data
    ),
    currentTitle: Yup.string().required("This field is required"),
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("This field is required")
      .matches(workEmailRegex, "Use your work email")
      .test(
        "not-existing-company-email",
        d => {
          const company = companyFromEmail(data, d.value);
          return (
            <>
              This company is already on Welcome to the Jungle. Sign in{" "}
              <Link
                to={`/signup/${company?.urlSafeName}`}
                data-testid="existing-signup-link"
              >
                here
              </Link>
              .
            </>
          );
        },
        email => {
          const company = companyFromEmail(data, email);
          if (company) {
            return false;
          }
          return true;
        }
      ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("This field is required"),
    marketingConsent: Yup.boolean().required("This field is required"),
    termsConditionsConsent: Yup.boolean()
      .isTrue("You must accept the terms and conditions")
      .required("This field is required"),
  });

const StyledCard = styled(Card)`
  margin: 0 auto;
`;

export function Onboarding({
  title,
  onError,
}: {
  title: string;
  onError?: (error: string) => void;
}) {
  const apolloClient = useApolloClient();

  const { data } = useQuery(CompaniesWithPreApprovalDocument);

  const [submitError, setSubmitError] = useState<string>();

  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);

  const [answers, setAnswers] = useAnswers();
  const hasCompanyDetails = Boolean(answers.companyName && answers.websiteUrl);

  const form = useFormik({
    initialValues: {
      companyName: answers.companyName ?? "",
      websiteUrl: answers.websiteUrl ?? "",
      shortDescription: answers.shortDescription ?? "",
      sectorTags: answers.sectorTags ?? [],
      email: answers.email || "",
      currentTitle: answers.currentTitle || "",
      firstName: answers.firstName || "",
      lastName: answers.lastName || "",
      password: "",
      marketingConsent: answers.marketingConsent || null,
      termsConditionsConsent: answers.termsConditionsConsent || false,
    },
    validationSchema: validationSchema(data),
    async onSubmit(values) {
      setSubmitError(undefined);
      setAnswers({
        ...answers,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        currentTitle: values.currentTitle,
        marketingConsent: values.marketingConsent,
        termsConditionsConsent: values.termsConditionsConsent,
      });

      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_HOST}/auth/signup/company`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...(csrf ? { "X-CSRF-Token": csrf } : {}),
            },
            body: JSON.stringify(values),
            credentials: "include",
          }
        );

        if (response.status === 400 && onError) {
          const responseBody = await response.json();
          onError(responseBody.error);
        }

        if (response.status === 409) {
          return setSubmitError("CONFLICT");
        }

        if (response.status !== 201) {
          throw new Error("Unexpected response");
        }
        if (!hasCompanyDetails) {
          window.open(
            "https://share.hsforms.com/1D_7K7wlkS0SL2VIVTCF7_g5gosb",
            "_blank"
          );
        }

        if (hasCompanyDetails && response.status === 201) {
          pushAnalyticsEvent({
            eventName: "company-recruiter-sign-up",
            method: "quiz",
          });
        }

        await apolloClient.resetStore();
      } catch (e) {
        setSubmitError("UNKNOWN");
      }
    },
  });

  const strippedWebsiteUrl = extractHost(answers.websiteUrl || "");

  return (
    <StyledCard>
      <Spacing size={2}>
        <Heading size={4}>{title}</Heading>
        <form onSubmit={form.handleSubmit}>
          <Spacing size={2}>
            {!hasCompanyDetails && (
              <Spacing size={-1}>
                <Text size={1}>Company details</Text>
                <FieldWrapper
                  label="Company name"
                  required
                  fieldError={
                    form.touched.companyName
                      ? form.errors.companyName
                      : undefined
                  }
                >
                  {({ field }) => (
                    <InputField
                      {...field}
                      disabled={form.isSubmitting}
                      name="companyName"
                      value={form.values.companyName}
                      onChange={form.handleChange("companyName")}
                      onBlur={form.handleBlur("companyName")}
                      autoFocus
                    />
                  )}
                </FieldWrapper>
                <FieldWrapper
                  label="Company website"
                  required
                  fieldError={
                    form.touched.websiteUrl ? form.errors.websiteUrl : undefined
                  }
                >
                  {({ field }) => (
                    <InputField
                      {...field}
                      disabled={form.isSubmitting}
                      name="websiteUrl"
                      value={form.values.websiteUrl}
                      onChange={form.handleChange("websiteUrl")}
                      onBlur={form.handleBlur("websiteUrl")}
                    />
                  )}
                </FieldWrapper>
              </Spacing>
            )}
            <Spacing size={-1}>
              {!hasCompanyDetails && <Text size={1}>Personal details</Text>}
              <FieldWrapper
                label="First name"
                required
                fieldError={
                  form.touched.firstName ? form.errors.firstName : undefined
                }
              >
                {({ field }) => (
                  <InputField
                    {...field}
                    disabled={form.isSubmitting}
                    name="firstName"
                    autoComplete="given-name"
                    value={form.values.firstName}
                    onChange={form.handleChange("firstName")}
                    onBlur={form.handleBlur("firstName")}
                    autoFocus={hasCompanyDetails}
                  />
                )}
              </FieldWrapper>
              <FieldWrapper
                label="Last name"
                required
                fieldError={
                  form.touched.lastName ? form.errors.lastName : undefined
                }
              >
                {({ field }) => (
                  <InputField
                    {...field}
                    disabled={form.isSubmitting}
                    name="lastName"
                    autoComplete="family-name"
                    value={form.values.lastName}
                    onChange={form.handleChange("lastName")}
                    onBlur={form.handleBlur("lastName")}
                  />
                )}
              </FieldWrapper>
              <FieldWrapper
                label="Current job title"
                required
                fieldError={
                  form.touched.currentTitle
                    ? form.errors.currentTitle
                    : undefined
                }
              >
                {({ field }) => (
                  <InputField
                    {...field}
                    disabled={form.isSubmitting}
                    name="currentTitle"
                    value={form.values.currentTitle}
                    onChange={form.handleChange("currentTitle")}
                    onBlur={form.handleBlur("currentTitle")}
                  />
                )}
              </FieldWrapper>
              <FieldWrapper
                label="Work email"
                required
                fieldError={form.touched.email ? form.errors.email : undefined}
              >
                {({ field }) => (
                  <InputField
                    {...field}
                    disabled={form.isSubmitting}
                    name="email"
                    warning={
                      strippedWebsiteUrl
                        ? `To get set up as quickly as possible we recommend using an email ending @${strippedWebsiteUrl}. Other addresses may result in a short delay.`
                        : undefined
                    }
                    type="email"
                    autoComplete="email"
                    value={form.values.email}
                    placeholder={
                      strippedWebsiteUrl
                        ? `user@${strippedWebsiteUrl}`
                        : undefined
                    }
                    onChange={form.handleChange("email")}
                    onBlur={form.handleBlur("email")}
                  />
                )}
              </FieldWrapper>
              <FieldWrapper
                label="Password"
                required
                fieldError={
                  form.touched.password ? form.errors.password : undefined
                }
              >
                {({ field }) => (
                  <PasswordField
                    {...field}
                    disabled={form.isSubmitting}
                    name="password"
                    autoComplete="new-password"
                    value={form.values.password}
                    onChange={form.handleChange("password")}
                    onBlur={form.handleBlur("password")}
                  />
                )}
              </FieldWrapper>
            </Spacing>
            <Spacing size={3}>
              <MarketingConsent
                name="marketingConsent"
                value={form.values.marketingConsent}
                disabled={form.isSubmitting}
                onChange={form.handleChange}
                error={
                  form.touched.marketingConsent
                    ? form.errors.marketingConsent
                    : undefined
                }
              />
              <TermsConditionsConsent
                name="termsConditionsConsent"
                checked={form.values.termsConditionsConsent}
                onChange={v => form.setFieldValue("termsConditionsConsent", v)}
                disabled={form.isSubmitting}
                error={
                  form.touched.termsConditionsConsent
                    ? form.errors.termsConditionsConsent
                    : undefined
                }
              />
            </Spacing>
            {submitError && (
              <ErrorText>
                {submitError === "CONFLICT" ? (
                  <>
                    This email is already used, try another or{" "}
                    <Link to="/login" style={{ display: "inline" }}>
                      sign in
                    </Link>
                    .
                  </>
                ) : submitError === "SSO_ENFORCED" ? (
                  "Your company enforces SSO, sign up with SSO instead."
                ) : (
                  "Something went wrong. Try again."
                )}
              </ErrorText>
            )}
            {hasCompanyDetails ? (
              <Button
                disabled={form.isSubmitting}
                type="submit"
                level="primary"
                style={{ minWidth: pxToRem(300) }}
              >
                {form.isSubmitting ? "Creating account..." : "Continue"}
              </Button>
            ) : (
              <Button
                disabled={form.isSubmitting}
                type="submit"
                level="primary"
                style={{ float: "right" }}
              >
                {form.isSubmitting ? "Creating..." : "Create account"}
              </Button>
            )}
          </Spacing>
        </form>
      </Spacing>
    </StyledCard>
  );
}
