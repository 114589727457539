import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { CompleteJobSlotCheckoutSessionDocument } from "@hire/schema";

export function useCompleteCheckoutSession() {
  const [params] = useSearchParams();
  const [completeSession] = useMutation(CompleteJobSlotCheckoutSessionDocument);

  useEffect(() => {
    const sessionId = params.get("id");
    if (sessionId) {
      completeSession({ variables: { checkoutSessionId: sessionId } });
    }
  }, [params, completeSession]);
}
