import { useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { subDays } from "date-fns/esm";

import {
  JobListMode,
  JobListStatus,
  toWorkflowStatuses,
  useJobListStatus,
} from "./statusTypes";

import {
  JobsListDocument,
  JobsListPotentialCandidatesDocument,
  JobsListQueryVariables,
  Location,
} from "@hire/schema";
import { stringToLocation } from "@hire/util/locations";
import { hireAppUser } from "@hire/util/user";

export const DISAPPROVED_JOBS_DISPLAY_PERIOD = 30;
const disapprovedJobsDisplayPeriodDay = subDays(
  new Date(),
  DISAPPROVED_JOBS_DISPLAY_PERIOD
).toISOString();

interface SearchParams {
  status?: string | undefined;
  locations?: Location[] | undefined;
}

export function useJobsList(mode: JobListMode) {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = useCallback(
    (newParams: SearchParams) => {
      setSearchParams(params => {
        return {
          ...Object.fromEntries(params.entries()),
          ...newParams,
        };
      });
    },
    [setSearchParams]
  );

  const status = useJobListStatus(mode, searchParams);

  const workflowStatuses = useMemo(
    () => (status !== "loading" ? toWorkflowStatuses(status) : []),
    [status]
  );

  const searchParamsLocation = searchParams.get("locations");
  const location = searchParamsLocation
    ? stringToLocation(searchParamsLocation)
    : undefined;

  const locations: Location[] = location ? [location] : [];
  const insertedAtGt =
    status === JobListStatus.DISAPPROVED
      ? disapprovedJobsDisplayPeriodDay
      : null;

  const isPaused =
    status === JobListStatus.UNPUBLISHED
      ? true
      : status === JobListStatus.PUBLISHED
      ? false
      : undefined;

  const variables: JobsListQueryVariables = {
    isPaused,
    workflowStatuses,
    locations,
    insertedAtGt,
  };

  const { data, loading, error, refetch } = useQuery(JobsListDocument, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: mode === null,
    variables,
  });

  const { data: potentialCandidatesData } = useQuery(
    JobsListPotentialCandidatesDocument,
    {
      variables,
    }
  );

  const company = useMemo(
    () => hireAppUser(data?.me)?.currentCompany,
    [data?.me]
  );

  const total = useMemo(
    () => hireAppUser(data?.me)?.currentCompany?.totalJobs,
    [data]
  );

  const jobs = useMemo(() => {
    const jobsWithPotentialCandidates = hireAppUser(potentialCandidatesData?.me)
      ?.currentCompany?.listJobs;
    if (!jobsWithPotentialCandidates) {
      return company?.listJobs;
    }
    return company?.listJobs.map(job => {
      const additionalData = jobsWithPotentialCandidates.find(
        ({ id }) => id === job.id
      );

      return {
        ...job,
        ...additionalData,
      };
    });
  }, [company, potentialCandidatesData]);

  return {
    updateSearchParams,
    loading: loading || status === "loading",
    status: status === "loading" ? undefined : status,
    company,
    jobs,
    locations,
    location,
    error,
    total,
    refetch: () => refetch(variables),
  };
}
