import { SourcingPreferencesFieldProps } from "./interfaces";

import { Checkbox, Spacing, Text } from "@otta/design";

export function SavedJobAndCompanyFields({
  onChange,
  preferences,
}: SourcingPreferencesFieldProps): React.ReactElement {
  return (
    <Spacing size={-2}>
      <Text bold>Interested candidates</Text>
      <Checkbox
        data-testid="saved-job-checkbox"
        label="Candidate saved this job"
        checked={!!preferences.savedJob}
        onChange={checked => {
          onChange({ savedJob: checked });
        }}
      />
      <Checkbox
        data-testid="saved-company-checkbox"
        label="Candidate saved a job at this company"
        checked={!!preferences.savedCompany}
        onChange={checked => {
          onChange({ savedCompany: checked });
        }}
      />
    </Spacing>
  );
}
