import { useQuery, useMutation } from "@apollo/client";
import { useMemo } from "react";

import {
  CompanyRoleType,
  Feature,
  MeDocument,
  NewUserUpsellDocument,
  UpdateSalaryVisibilityDocument,
  UpdateViewSalaryPermissionDocument,
  ViewJobSalaryRecruitersDocument,
  ViewJobSalaryRecruitersQuery,
} from "@hire/schema";
import { useAuthorizations } from "@hire/providers/authorization";

export function useGetCompanyMembers(jobExternalId: string) {
  const { data: currentUserData, loading: currentUserLoading } =
    useQuery(MeDocument);
  const { data, loading, refetch } = useQuery(ViewJobSalaryRecruitersDocument, {
    variables: { jobId: jobExternalId },
  });
  const { updateVisibilityMutation, updatePermissions } = useUpdatePermissions(
    data?.companyJob ?? null
  );
  const currentUserId = currentUserData?.me?.id;
  const hideSalary = !!data?.companyJob?.hideSalary;
  const company = data?.companyJob?.company;

  const companyMembers = useMemo(() => {
    return (company?.companyRecruiters ?? []).filter(
      recruiter => recruiter.role === CompanyRoleType.Member
    );
  }, [company?.companyRecruiters]);

  const recruiterIdsThatCanView = useMemo(() => {
    return companyMembers
      .filter(member => !!member.viewSalaryForJob)
      .map(member => member.id);
  }, [companyMembers]);

  const hiddenFromCount = companyMembers.filter(
    recruiter => !recruiter.viewSalaryForJob
  ).length;

  return {
    currentUserId,
    companyMembers,
    recruiterIdsThatCanView,
    hiddenFromCount,
    hideSalary,
    loading: loading || currentUserLoading,
    refetch,
    updateVisibilityMutation,
    updatePermissions,
  };
}

function useUpdatePermissions(job: ViewJobSalaryRecruitersQuery["companyJob"]) {
  const [updateVisibilityMutation] = useMutation(
    UpdateSalaryVisibilityDocument,
    {
      optimisticResponse: {
        updateSalaryVisibility: job
          ? {
              ...job,
              hideSalary: !job.hideSalary,
              company: {
                ...job.company,
                companyRecruiters: job.company.companyRecruiters.map(
                  recruiter => ({
                    ...recruiter,
                    viewSalaryForJob: true,
                  })
                ),
              },
            }
          : null,
      },
    }
  );

  const [updatePermissions] = useMutation(UpdateViewSalaryPermissionDocument, {
    optimisticResponse({ userIds }) {
      return {
        updateViewSalaryPermission: job
          ? {
              ...job,
              company: {
                ...job.company,
                companyRecruiters: job.company.companyRecruiters.map(
                  recruiter => ({
                    ...recruiter,
                    viewSalaryForJob:
                      recruiter.role === CompanyRoleType.Admin ||
                      userIds.includes(recruiter.id),
                  })
                ),
              },
            }
          : null,
      };
    },
  });

  return { updateVisibilityMutation, updatePermissions };
}

export function useNewUsersUpsell() {
  const { loading, data } = useQuery(NewUserUpsellDocument);

  const showUpsell = useMemo(() => {
    return (
      (data?.me &&
        data.me.__typename === "CurrentCompanyRecruiter" &&
        data.me.isFirstLogin &&
        data.me.currentCompany &&
        !data.me.currentCompany.isCurrentPayingCustomer) ??
      false
    );
  }, [data]);

  return { showUpsell, loading };
}

export function useUnifiedOfferFeatures() {
  const { features } = useAuthorizations([
    Feature.Articles,
    Feature.Photos,
    Feature.Values,
    Feature.DeiInitiativesLight,
    Feature.PeopleProgressing,
    Feature.Endorsements,
  ]);

  const featureGrants = useMemo(() => {
    const articlesFeature = features.get(Feature.Articles)?.granted;
    const photosFeature = features.get(Feature.Photos)?.granted;
    const valuesFeature = features.get(Feature.Values)?.granted;
    const deiInitiativesLightFeature = features.get(
      Feature.DeiInitiativesLight
    )?.granted;
    const peopleProgressingFeature = features.get(
      Feature.PeopleProgressing
    )?.granted;
    const endorsementsFeature = features.get(Feature.Endorsements)?.granted;

    return {
      valuesFeature: {
        granted: valuesFeature,
        showUpsell: !valuesFeature,
      },
      articlesFeature: {
        granted: articlesFeature,
        showUpsell: valuesFeature && !articlesFeature,
      },
      photosFeature: {
        granted: photosFeature,
        showUpsell: valuesFeature && articlesFeature && !photosFeature,
      },
      deiInitiativesLightFeature: {
        granted: deiInitiativesLightFeature,
        showUpsell: !deiInitiativesLightFeature,
      },
      peopleProgressingFeature: {
        granted: peopleProgressingFeature,
        showUpsell: !peopleProgressingFeature,
      },
      endorsementsFeature: {
        granted: endorsementsFeature,
        showUpsell: !endorsementsFeature,
      },
    };
  }, [features]);

  return featureGrants;
}
