import styled from "@xstyled/styled-components";
import { useCallback, useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import { useRefetchConversations } from "../Context";

import { CandidateHeaderInfo } from "./CandidateHeaderInfo";
import { ArchiveButton } from "./ArchiveButton";

import {
  CompanyConversationDocument,
  CreateCandidateCvPdfDocument,
  Feature,
  GetJobPipelineDocument,
  JobFeature,
  SendCandidateToAtsDocument,
  StageType,
  UpdateCandidatePipelineItemStageDocument,
} from "@hire/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Button, SelectField, Text, VerticalSpacing } from "@otta/design";
import { TabLink } from "@hire/components/links/TabLink";
import {
  useAuthorization,
  useJobAuthorization,
} from "@hire/providers/authorization";
import { handleMutationError } from "@hire/errors";

const Container = styled.div`
  grid-area: top;
  background-color: white;
  padding: xl xl 0;
  border-bottom: 1px solid ${palette.grayscale.shade200};
`;

const TabsContainer = styled.div`
  display: flex;
  gap: lg;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: lg;
`;

interface HeaderCandidate {
  id: string;
  externalId: string;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  websiteLinks: { id: string; url: string }[];
  linkedinUrl: string | null;
}

function SendToAts({
  jobId,
  candidateId,
  profile,
}: {
  jobId: string;
  candidateId: string;
  profile: { url: string | null } | null;
}) {
  const [send, { data, loading: submitting }] = useMutation(
    SendCandidateToAtsDocument,
    { variables: { jobId, candidateId }, onError: handleMutationError }
  );

  const sentToATS = profile ?? data?.sendCandidateToAts?.atsProfile;
  const profileUrl = profile?.url ?? data?.sendCandidateToAts?.atsProfile?.url;

  if (sentToATS) {
    if (profileUrl) {
      return (
        <Button
          as="a"
          level="primary"
          target="_blank"
          rel="noopener noreferrer"
          href={profileUrl}
          style={{ whiteSpace: "nowrap" }}
        >
          View in ATS
        </Button>
      );
    } else {
      return (
        <Button level="primary" disabled style={{ whiteSpace: "nowrap" }}>
          Sent to ATS
        </Button>
      );
    }
  } else {
    return (
      <Button
        level="primary"
        disabled={submitting}
        style={{ whiteSpace: "nowrap" }}
        onClick={() => {
          send();
        }}
      >
        {submitting ? "Sending..." : "Send to ATS"}
      </Button>
    );
  }
}

interface CandidateHeaderProps {
  candidate: HeaderCandidate;
  jobId: string;
  candidateId: string;
  showConversationTab: boolean;
  atsProfile: { url: string | null } | null;
  archived: boolean;
  conversationId: string | null;
}

export function CandidateHeader({
  candidate,
  jobId,
  candidateId,
  showConversationTab,
  atsProfile,
  archived,
  conversationId,
}: CandidateHeaderProps) {
  const { granted: canUseJobPipeline } = useJobAuthorization(
    JobFeature.Pipeline
  );

  const { data: conversationData, refetch: refetchConversation } = useQuery(
    CompanyConversationDocument,
    {
      variables: {
        jobId,
        candidateId,
      },
    }
  );

  const { data: jobPipelineData } = useQuery(GetJobPipelineDocument, {
    variables: { jobId, workExperienceLimit: 1 },
    skip: !canUseJobPipeline,
  });

  useCallback(async () => {
    await refetchConversation();
  }, [refetchConversation]);

  const stageId: string | null =
    conversationData?.getJobPipelineItem?.stageId ?? null;
  const itemId: string | null =
    conversationData?.getJobPipelineItem?.id ?? null;

  const atsAccess = useAuthorization(Feature.AtsIntegration);

  const [createCV, { loading: creatingCV }] = useMutation(
    CreateCandidateCvPdfDocument,
    {
      onError: handleMutationError,
    }
  );

  return (
    <Container>
      <VerticalSpacing>
        <Row>
          <CandidateHeaderInfo candidate={candidate} />
          <div style={{ flex: 1 }} />
          {canUseJobPipeline && stageId && itemId && (
            <CandidateHeaderStageSelector
              jobId={jobId}
              currentStageId={stageId}
              candidateItemId={itemId}
              stages={
                jobPipelineData?.getJobPipeline?.candidatePipelineStages ?? null
              }
            />
          )}
          {!canUseJobPipeline && conversationId && (
            <ArchiveButton id={conversationId} archived={archived} />
          )}
          {atsAccess.granted && (
            <SendToAts
              jobId={jobId}
              candidateId={candidate.externalId}
              profile={atsProfile}
            />
          )}
          <Button
            level="secondary"
            type="button"
            onClick={async () => {
              const result = await createCV({
                variables: {
                  candidateId: candidate.id,
                },
              });
              const url = result?.data?.createCandidateCvPdf?.url;
              if (url) {
                window.open(url);
              }
            }}
            disabled={creatingCV}
          >
            {creatingCV ? "Exporting..." : "Download CV"}
          </Button>
        </Row>
        <TabsContainer>
          <TabLink to={{ pathname: "profile", search: location.search }}>
            Profile
          </TabLink>
          {showConversationTab && (
            <TabLink
              to={{
                pathname: "conversation",
                search: location.search,
              }}
            >
              Conversation
            </TabLink>
          )}
          {itemId && (
            <TabLink to={{ pathname: "notes", search: location.search }}>
              Notes
            </TabLink>
          )}
        </TabsContainer>
      </VerticalSpacing>
    </Container>
  );
}

export function CandidateHeaderStageSelector({
  jobId,
  currentStageId,
  candidateItemId,
  stages,
}: {
  jobId: string;
  currentStageId: string;
  candidateItemId: string;
  stages: { id: string; name: string; stageType: StageType }[] | null;
}) {
  const refetchConversations = useRefetchConversations();

  const [updateCandidateStage, { loading: submitting }] = useMutation(
    UpdateCandidatePipelineItemStageDocument,
    {
      onCompleted: () => {
        refetchConversations();
      },
      onError: handleMutationError,
    }
  );

  const options = useMemo(
    () =>
      stages?.map(s => ({
        label: s.name,
        value: s.id,
      })) ?? [],
    [stages]
  );

  const currentValue = useMemo(
    () => options.find(v => v.value === currentStageId),
    [currentStageId, options]
  );

  return (
    <div style={{ display: "flex", alignItems: "center", gap: pxToRem(8) }}>
      <Text as="label" htmlFor="candidate-stage-selector">
        Stage:
      </Text>
      <SelectField
        inputId="candidate-stage-selector"
        isClearable={false}
        options={options}
        value={currentValue}
        onChange={option => {
          if (option) {
            updateCandidateStage({
              variables: {
                jobId,
                itemId: candidateItemId,
                stageId: option.value,
                workExperienceLimit: 1,
              },
              update(cache, result) {
                if (!result.data?.updateCandidatePipelineItemStage) {
                  return;
                }
                const item = stages?.find(s => s.id === option.value);

                if (!item) {
                  return;
                }

                cache.writeFragment({
                  fragment: gql`
                    fragment StageId on CandidatePipelineItem {
                      stageId
                      stageName
                      stageType
                    }
                  `,
                  id: cache.identify({
                    __typename: "CandidatePipelineItem",
                    id: candidateItemId,
                  }),
                  data: {
                    stageId: item.id,
                    stageName: item.name,
                    stageType: item.stageType,
                  },
                });
              },
            });
          }
        }}
        isDisabled={submitting}
        isLoading={submitting}
      />
    </div>
  );
}
