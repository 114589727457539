import { useId } from "react";
import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import { Text, theme } from "@otta/design";

const InvisibleRadio = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
`;

const RadioButton = styled.span`
  height: 20;
  width: 20;
  border-radius: 50%;
  background-color: gray-200;
  margin-right: sm;
  transition: default;
  cursor: pointer;

  &:hover {
    background-color: gray-400;
  }

  ${InvisibleRadio}:focus-visible + & {
    box-shadow: 0 0 0 2px white, 0 0 0 4px ${theme.colors["yellow-500"]};
  }
`;

const Label = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  &:hover span {
    background-color: gray-400;
  }
  input:checked ~ span {
    background-color: black;
    border: ${pxToRem(5.5)} solid ${palette.brand.yellow};
  }
`;

const StyledText = styled(Text)`
  display: inline;
  margin-right: 19;
`;

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  size?: number;
  name: string;
  label: string;
}

export function Radio({
  label,
  name,
  size = 0,
  ...rest
}: RadioProps): React.ReactElement {
  const id = useId();
  return (
    <Label htmlFor={id}>
      <InvisibleRadio {...rest} type="radio" id={id} name={name} />
      <RadioButton />
      <StyledText size={size}>{label}</StyledText>
    </Label>
  );
}
