import { useQuery } from "@apollo/client";
import { styled } from "@xstyled/styled-components";

import { PageHeading } from "./PageHeading";
import { NoEndorsements } from "./NoEndorsements";
import { EndorsementHighlights } from "./EndorsementHighlights";
import { EndorsementList } from "./EndorsementList";

import { CompanyEmployeeEndorsementsDocument } from "@hire/schema";
import { Spacing } from "@otta/design";
import { CardContainer } from "@hire/components/containers/CardContainer";
import { useUnifiedOfferFeatures } from "@hire/util/hooks";

const Overlay = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  height: 0;
  width: 100%;
  pointer-events: none;
  background-color: gray-50;
  opacity: 0.5;
  z-index: 1;
  &[aria-disabled="true"] {
    height: 100%;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

export const Endorsements = () => {
  const { data } = useQuery(CompanyEmployeeEndorsementsDocument);

  const company = data?.currentUser?.currentCompany;

  const { endorsementsFeature } = useUnifiedOfferFeatures();

  if (!company) {
    return null;
  }

  const hasEndorsements = company.employeeSurveys.length > 0;
  return (
    <>
      <PageHeading
        urlSafeName={company.urlSafeName}
        featureGranted={endorsementsFeature?.granted}
        showUpsell={endorsementsFeature.showUpsell}
      />

      <CardContainer>
        <Wrapper>
          <Overlay aria-disabled={!endorsementsFeature.granted} />
          {hasEndorsements ? (
            <Spacing size={2}>
              <EndorsementHighlights company={company} />
              <EndorsementList company={company} />
            </Spacing>
          ) : (
            <NoEndorsements companyName={company.name} />
          )}
        </Wrapper>
      </CardContainer>
    </>
  );
};
