import styled from "@xstyled/styled-components";
import { useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { BreadCrumbs } from "./Breadcrumbs";
import { OttasTakeModal } from "./OttasTakeModal";
import { useRequireLoginState } from "./useRequireLoginState";

import {
  Button,
  FieldWrapper,
  Heading,
  InputField,
  SelectField,
  Spacing,
  Text,
} from "@otta/design";
import * as Banner from "@hire/components/Banner";
import { pxToRem } from "@otta/design-tokens";
import { QuizAnswers, useAnswers } from "@hire/components/layout/QuizLayout";
import {
  CompaniesWithPreApprovalDocument,
  CompaniesWithPreApprovalQuery,
  CompanyDetailsOptionsDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { validateDomain } from "@hire/validation/domain";

const BannerContent = styled(Banner.Content)`
  gap: md;
`;

const SeeExampleLink = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

const baseValidation = (data: CompaniesWithPreApprovalQuery | undefined) => {
  return {
    companyName: Yup.string().required("This field is required"),
    websiteUrl: validateDomain(
      Yup.string().required("This field is required"),
      data
    ),
  };
};

const starterValidationSchema = (
  data?: CompaniesWithPreApprovalQuery | undefined
) =>
  Yup.object().shape({
    ...baseValidation(data),
    shortDescription: Yup.string()
      .trim()
      .min(5)
      .max(100)
      .required("This field is required")
      .label("Short description"),
    sectorTags: Yup.array()
      .required()
      .min(1, "Choose at least one sector tag")
      .max(10, "Choose at most 10 sector tags"),
  });

export const CompanyDetails = () => {
  const [exampleModalVisible, setExampleModalVisible] = useState(false);
  const { data } = useQuery(CompaniesWithPreApprovalDocument);

  const { loading } = useRequireLoginState({
    state: "logged-out",
    redirect: "/quiz",
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <BreadCrumbs currentBreadcrumb="Company" />
      <Spacing size={2}>
        <Heading size={2}>Tell us about your company</Heading>

        <Banner.Info>
          <BannerContent>
            <Text>
              We'll set you up for success with a detailed company profile. You
              can edit this later.
            </Text>
            <Text size={0}>
              We'll also put in a good word for you with candidates.{" "}
              <SeeExampleLink
                onClick={() => {
                  setExampleModalVisible(true);
                }}
                inline
              >
                See example
              </SeeExampleLink>
              .
            </Text>
          </BannerContent>
        </Banner.Info>

        <StarterForm data={data} />
      </Spacing>

      <Text style={{ marginTop: pxToRem(12) }}>
        <Text inline bold>
          No commitment.
        </Text>{" "}
        Pay monthly, cancel online anytime.
      </Text>

      <OttasTakeModal
        open={exampleModalVisible}
        closeModal={() => setExampleModalVisible(false)}
      />
    </>
  );
};

const StarterForm = ({
  data,
}: {
  data: CompaniesWithPreApprovalQuery | undefined;
}) => {
  const [answers, setAnswers] = useAnswers();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { error: shortDescriptionError } = state || {};

  const optionsQuery = useQuery(CompanyDetailsOptionsDocument);

  const sectorTagsOptions = useMemo(
    () =>
      optionsQuery?.data?.sectorTags.map(o => ({
        label: o.value,
        value: o.id,
      })),
    [optionsQuery]
  );

  const form = useFormik<QuizAnswers>({
    initialValues: answers,
    validationSchema: starterValidationSchema(data),
    async onSubmit(values) {
      setAnswers({
        ...answers,
        companyName: values.companyName,
        websiteUrl: values.websiteUrl,
        shortDescription: values.shortDescription,
        sectorTags: values.sectorTags,
      });

      navigate("/quiz/signup");
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Spacing size={2}>
        <FieldWrapper
          label="Company name"
          fieldError={
            form.touched.companyName ? form.errors.companyName : undefined
          }
          required
        >
          {({ field }) => (
            <InputField
              disabled={form.isSubmitting}
              name="companyName"
              value={form.values.companyName}
              onChange={form.handleChange("companyName")}
              onBlur={form.handleBlur("companyName")}
              aria-describedby={field["aria-describedby"]}
              aria-invalid={field["aria-invalid"]}
              autoFocus
            />
          )}
        </FieldWrapper>
        <FieldWrapper
          label="Website URL"
          fieldError={
            form.touched.websiteUrl ? form.errors.websiteUrl : undefined
          }
          required
        >
          {({ field }) => (
            <InputField
              disabled={form.isSubmitting}
              name="websiteUrl"
              value={form.values.websiteUrl}
              onChange={form.handleChange("websiteUrl")}
              onBlur={form.handleBlur("websiteUrl")}
              aria-describedby={field["aria-describedby"]}
              aria-invalid={field["aria-invalid"]}
            />
          )}
        </FieldWrapper>
        <FieldWrapper
          label="Short description"
          advice="5-10 words explaining what the company does."
          fieldError={
            shortDescriptionError
              ? `Your short description ${shortDescriptionError
                  .split(" ")
                  .slice(1)
                  .join(" ")}`
              : form.touched.shortDescription
              ? form.errors.shortDescription
              : undefined
          }
          required
        >
          {({ field }) => (
            <InputField
              value={form.values.shortDescription}
              name="shortDescription"
              onChange={form.handleChange("shortDescription")}
              onBlur={form.handleBlur("shortDescription")}
              aria-describedby={field["aria-describedby"]}
              aria-invalid={field["aria-invalid"]}
            />
          )}
        </FieldWrapper>
        <FieldWrapper
          label="Sector tags"
          fieldError={
            form.touched.sectorTags ? form.errors.sectorTags : undefined
          }
          required
        >
          {({ field }) => (
            <SelectField
              inputId={field.id}
              name="sectorTags"
              value={form.values.sectorTags}
              options={sectorTagsOptions}
              onChange={v => form.setFieldValue("sectorTags", v)}
              aria-describedby={field["aria-describedby"]}
              aria-invalid={field["aria-invalid"]}
              isMulti
            />
          )}
        </FieldWrapper>
      </Spacing>
      <Button
        style={{ marginTop: pxToRem(24) }}
        disabled={form.isSubmitting}
        level="primary"
        type="submit"
      >
        Continue to account creation
      </Button>
    </form>
  );
};
