import { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { subDays } from "date-fns";
import { isDate, max } from "date-fns/esm";

import { DISAPPROVED_JOBS_DISPLAY_PERIOD } from "./useJobsList";

import { Button, Clickable, Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { pushAnalyticsEvent } from "@otta/analytics";
import { DisapprovedJobsDocument, StarterOrBasicDocument } from "@hire/schema";
import { hireAppUser } from "@hire/util/user";

const disapprovedJobsAlertLocalStorageKey = "disapproved-jobs-alert";

const getDisapprovedJobsDisplayPeriodDay = () => {
  const disapprovedJobsAlertStorageValue = localStorage.getItem(
    disapprovedJobsAlertLocalStorageKey
  );

  const defaultDisapprovedJobsDisplayPeriodDay = subDays(
    new Date(),
    DISAPPROVED_JOBS_DISPLAY_PERIOD
  ).toISOString();
  let disapprovedJobsDisplayPeriodDay = defaultDisapprovedJobsDisplayPeriodDay;
  if (
    disapprovedJobsAlertStorageValue &&
    isDate(new Date(disapprovedJobsAlertStorageValue))
  ) {
    disapprovedJobsDisplayPeriodDay = max([
      new Date(disapprovedJobsAlertStorageValue),
      new Date(defaultDisapprovedJobsDisplayPeriodDay),
    ]).toISOString();
  }

  // We remove the hours/minutes/seconds to prevent unnecessary re-renders
  const date = new Date(disapprovedJobsDisplayPeriodDay);
  date.setHours(0, 0, 0, 0);
  return date.toISOString();
};

export const DisapprovedJobsAlert = () => {
  const { data } = useQuery(DisapprovedJobsDocument, {
    variables: {
      insertedAtGt: getDisapprovedJobsDisplayPeriodDay(),
    },
  });
  const { data: planData } = useQuery(StarterOrBasicDocument);

  const user = hireAppUser(data?.me);
  const userWithPlan = hireAppUser(planData?.me);
  const hasCSM = userWithPlan?.currentCompany?.currentCustomerPlans?.some(
    plan => {
      return plan && /^(Pro|Advanced)/.test(plan);
    }
  );
  const totalJobs = user?.currentCompany?.totalJobs ?? 0;
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (totalJobs > 0) {
      setIsVisible(true);
    }
  }, [totalJobs]);

  const { pathname } = useLocation();
  useEffect(() => {
    if (isVisible) {
      pushAnalyticsEvent({
        eventName: "Company Recruiter Viewed",
        name: "disapproved-jobs-notification",
        pathName: pathname,
      });
    }
  }, [pathname, isVisible]);

  if (!isVisible) return null;

  const handleClickClose = () => {
    setIsVisible(false);
    localStorage.setItem(
      disapprovedJobsAlertLocalStorageKey,
      new Date().toISOString()
    );
  };

  return (
    <x.div
      display="flex"
      backgroundColor="red-200"
      padding="lg"
      borderRadius="lg"
    >
      <x.div
        display="flex"
        alignItems="flex-start"
        data-testid="disapproved-jobs-alert"
      >
        <x.div display="flex" flexDirection="column" gap="lg">
          <x.div display="flex" gap="sm">
            <Icon icon="circle-close" />
            <Text bold>You have a new disapproved job</Text>
          </x.div>
          <Text>A job you&apos;ve created couldn&apos;t be posted.</Text>
          <Text>
            Filter for disapproved jobs to see the reason and check{" "}
            <x.a
              color="inherit"
              fontWeight="600"
              href="https://support.welcometothejungle.com/en/articles/7178480-why-isn-t-my-job-live-on-welcome-to-the-jungle"
              target="_blank"
              rel="noopener noreferrer"
            >
              this article
            </x.a>{" "}
            to learn more
            {hasCSM && " and contact your success manager for support"}.
          </Text>

          <Button
            level="secondary"
            data-testid="disapproved-jobs-alert-view-jobs-button"
            data-analytics-id="disapproved-jobs-alert-view-jobs-button"
            onClick={handleClickClose}
          >
            <x.span
              as={Link}
              to="/jobs?status=disapproved"
              color="inherit"
              textDecoration="none"
            >
              View disapproved jobs
            </x.span>
          </Button>
        </x.div>
        <Clickable
          onClick={handleClickClose}
          data-testid="disapproved-jobs-alert-close-button"
          data-analytics-id="disapproved-jobs-alert-close-button"
        >
          <Icon icon="close" size={1} />
        </Clickable>
      </x.div>
    </x.div>
  );
};
