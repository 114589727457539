import { Form, Formik, FormikErrors, FormikState } from "formik";
import { useCallback, useMemo, useRef } from "react";
import * as Yup from "yup";
import styled from "@xstyled/styled-components";

import { ArticleForm } from "./ArticleModal/ArticleForm";
import { ArticlePreview } from "./ArticleModal/ArticlePreview";
import { OttasTake } from "./OttasTake";
import { transformIn, transformOut } from "./transformers";
import { CompanyDetailsFormFields } from "./types";
import { Upsell } from "./Upsell";

import {
  CompanyDetailsFragment,
  CompanyDetailsOptionsQuery,
  CompanyInput,
  ImageCategory,
} from "@hire/schema";
import {
  Button,
  FieldContainer,
  FieldWrapper,
  InputField,
  Label,
  SelectField,
  Spacing,
  Tipbox,
} from "@otta/design";
import { Bullets } from "@hire/components/form/Bullets";
import { FieldErrors } from "@hire/components/form/FieldErrors";
import { ImageUploadField } from "@hire/components/form/ImageUploadField";
import { EmptyList, ItemCardList } from "@hire/components/form/ItemCardList";
import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";
import { FormikRange } from "@hire/components/form/Range/formik";
import { Textarea } from "@hire/components/input/Textarea";
import {
  mustNotContainEmail,
  mustNotContainUrl,
} from "@hire/validation/strings";
import { officeDaysSchema } from "@hire/components/form/Range/schema";
import { useUnifiedOfferFeatures } from "@hire/util/hooks";

const StyledFieldWrapper = styled(FieldWrapper)`
  margin-left: -1rem;
  text-align: left;
`;

const formatPhotosErrors = (form: FormikState<CompanyDetailsFormFields>) => {
  return form.touched.photos
    ? (
        (form.errors.photos as FormikErrors<
          CompanyDetailsFormFields["photos"]
        >) ?? []
      ).reduce((acc, curr) => {
        if (curr?.path) {
          acc.push(curr.path);
        }
        return acc;
      }, [] as string[])
    : undefined;
};

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Fill in this field")
    .trim()
    .min(3)
    .max(40)
    .label("Company name"),
  websiteUrl: Yup.string()
    .trim()
    .url()
    .required("Fill in this field")
    .max(127)
    .label("Website URL"),
  shortDescription: Yup.string()
    .trim()
    .min(5)
    .max(100)
    .required("Fill in this field")
    .label("Short description"),
  sectorTags: Yup.array()
    .min(1, "Choose at least one sector tag")
    .max(10, "Choose at most 10 sector tags"),
  logoPath: Yup.string().required("Add a logo").label("Logo"),
  faviconPath: Yup.string().required("Add a favicon").label("Favicon"),
  mission: Yup.string()
    .required("Fill in this field")
    .min(30)
    .max(500)
    .label("Mission"),
  values: Yup.array(
    Yup.object({
      order: Yup.number(),
      value: Yup.string()
        .test(mustNotContainUrl)
        .test(mustNotContainEmail)
        .optional()
        .label("Value"),
    })
  )
    .optional()
    .label("Values"),
  articles: Yup.array().optional(),
  photos: Yup.array().max(10).optional(),
  requiredOfficeDaysRange: officeDaysSchema(),
  hqAddress: Yup.string()
    .min(8)
    .required("Fill in this field")
    .label("Address"),
});

export const CompanyDetailsForm = ({
  data,
  options,
  handleSave,
}: {
  data: CompanyDetailsFragment;
  options: CompanyDetailsOptionsQuery;
  handleSave: (input: CompanyInput) => void;
}) => {
  const initialValues = useMemo(() => transformIn(data), [data]);

  const sectorTagsOptions = useMemo(
    () => options.sectorTags.map(o => ({ label: o.value, value: o.id })),
    [options]
  );

  const scrollToError = (
    errors: FormikState<CompanyDetailsFormFields>["errors"]
  ) => {
    const firstErrorKey = Object.keys(errors)[0];
    // Label because the element itself could be input[hidden] and thus not scrollable to
    const firstElementLabel = document.querySelector(
      `label[for="${firstErrorKey}"]`
    );
    if (firstElementLabel) {
      firstElementLabel.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const onSubmit = useCallback(
    (values: CompanyDetailsFormFields) => {
      return handleSave(transformOut(values));
    },
    [handleSave]
  );

  const { articlesFeature, photosFeature, valuesFeature } =
    useUnifiedOfferFeatures();

  const submitCountRef = useRef(0);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {form => {
        if (form.submitCount > submitCountRef.current && !form.isValid) {
          submitCountRef.current = form.submitCount;
          scrollToError(form.errors);
        }

        return (
          <Form>
            <Spacing size={2}>
              <InputField
                value={form.values.name}
                name="name"
                label="Company name"
                onChange={form.handleChange("name")}
                onBlur={form.handleBlur("name")}
                error={form.touched.name ? form.errors.name : undefined}
              />

              <InputField
                value={form.values.websiteUrl}
                name="websiteUrl"
                label="Website URL"
                onChange={form.handleChange("websiteUrl")}
                onBlur={form.handleBlur("websiteUrl")}
                error={
                  form.touched.websiteUrl ? form.errors.websiteUrl : undefined
                }
              />

              <MoreInfoFieldWrapper
                label="Short description"
                fieldName="shortDescription"
                guidance="5-10 words explaining what the company does."
              >
                <InputField
                  value={form.values.shortDescription}
                  name="shortDescription"
                  onChange={form.handleChange("shortDescription")}
                  onBlur={form.handleBlur("shortDescription")}
                  error={
                    form.touched.shortDescription
                      ? form.errors.shortDescription
                      : undefined
                  }
                />
              </MoreInfoFieldWrapper>

              <MoreInfoFieldWrapper
                label="Sector tags"
                fieldName="sectorTags"
                guidance="Select the tags that describe your sector."
              >
                <>
                  <SelectField
                    inputId="sectorTags"
                    name="sectorTags"
                    value={form.values.sectorTags}
                    options={sectorTagsOptions}
                    onChange={v => form.setFieldValue("sectorTags", v)}
                    onBlur={form.handleBlur("sectorTags")}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                  <FieldErrors
                    errors={
                      form.touched.sectorTags &&
                      form.errors.sectorTags &&
                      String(form.errors.sectorTags)
                    }
                  />
                </>
              </MoreInfoFieldWrapper>

              <MoreInfoFieldWrapper
                label="Rectangular logo"
                fieldName="logoPath"
                guidance="We recommend uploading a rectangular logo with the company name clearly visible."
              >
                <ImageUploadField
                  id="logoPath"
                  nounSingular="your logo"
                  nounPlural="logos"
                  fileCountMax={1}
                  value={
                    form.values.logoPath ? [{ path: form.values.logoPath }] : []
                  }
                  onChange={e =>
                    form.setFieldValue("logoPath", e.length ? e[0].path : null)
                  }
                  category={ImageCategory.CandidateCompanyLogo}
                  error={
                    form.touched.logoPath ? form.errors.logoPath : undefined
                  }
                />
              </MoreInfoFieldWrapper>

              <FieldContainer>
                <Label htmlFor="faviconPath">Square favicon</Label>
                <ImageUploadField
                  id="faviconPath"
                  nounPlural="favicons"
                  nounSingular="your favicon"
                  fileCountMax={1}
                  value={
                    form.values.faviconPath
                      ? [{ path: form.values.faviconPath }]
                      : []
                  }
                  onChange={e =>
                    form.setFieldValue(
                      "faviconPath",
                      e.length ? e[0].path : null
                    )
                  }
                  category={ImageCategory.Favicon}
                  error={
                    form.touched.faviconPath
                      ? form.errors.faviconPath
                      : undefined
                  }
                />
              </FieldContainer>

              <MoreInfoFieldWrapper
                label="Company mission"
                fieldName="mission"
                guidance="A short sentence on what your company wants to achieve (e.g. 'To become the world's career champion')."
              >
                <Textarea
                  value={form.values.mission}
                  name="mission"
                  onChange={form.handleChange("mission")}
                  onBlur={form.handleBlur("mission")}
                  error={form.touched.mission ? form.errors.mission : undefined}
                  aria-invalid={!!form.errors.mission}
                />
              </MoreInfoFieldWrapper>

              <MoreInfoFieldWrapper
                label="Company values"
                optional
                fieldName="values"
                featureGranted={valuesFeature.granted}
              >
                <Spacing>
                  {valuesFeature.showUpsell && (
                    <Upsell
                      title="Share your company values"
                      listItems={[
                        "Prominently feature company values on your profile and job listings",
                        "Highlighting your values attracts candidates who align with the company's long-term mission and culture",
                        "Clarify what the company stands for and the behaviours it prioritises and celebrates",
                        "Candidates feel inspired and motivated by values that resonate with their own",
                      ]}
                    />
                  )}
                  <Bullets
                    fieldName="values"
                    nounSingular="value"
                    values={form.values.values}
                    placeholders={[
                      "Boldness",
                      "Generosity",
                      "Accountability",
                      "Integrity",
                      "Passion",
                    ]}
                    onChange={v => form.setFieldValue("values", v)}
                    onBlur={form.handleBlur("values")}
                    errors={
                      form.touched.values &&
                      // Formik types all errors as strings even for nested values
                      (
                        (form.errors.values as FormikErrors<
                          CompanyDetailsFormFields["values"]
                        >) ?? []
                      ).map(e => e && e.value)
                    }
                    featureGranted={valuesFeature.granted}
                  />
                </Spacing>
              </MoreInfoFieldWrapper>

              <MoreInfoFieldWrapper
                label="Articles"
                fieldName="articles"
                guidance="We recommend up to 5 recent articles highlighting developments or milestones for the business (e.g. funding rounds and product launches). This helps candidates write stronger applications."
                optional
                featureGranted={articlesFeature.granted}
              >
                <Spacing>
                  {articlesFeature.showUpsell && (
                    <Upsell
                      title="Share recent updates and news"
                      listItems={[
                        "Show candidates your latest news, from your profile",
                        "Showcase up to 5 articles that link to your blog, news sites or other media",
                        "Help candidates write stronger applications, informed by the most up-to-date and relevant news",
                      ]}
                    />
                  )}
                  <ItemCardList
                    name="articles"
                    data={form.values.articles}
                    renderPreview={ArticlePreview}
                    renderForm={props => <ArticleForm {...props} />}
                    onChange={v => form.setFieldValue("articles", v)}
                    renderEmpty={() => (
                      <EmptyList
                        icon="article"
                        text="Add articles to your profile"
                      />
                    )}
                    featureGranted={articlesFeature.granted}
                  />
                </Spacing>
              </MoreInfoFieldWrapper>

              <MoreInfoFieldWrapper
                label="Photos"
                fieldName="photos"
                optional
                featureGranted={photosFeature.granted}
              >
                <Spacing>
                  {photosFeature.showUpsell && (
                    <Upsell
                      title="Bring your profile to life with photos"
                      listItems={[
                        "Add up to 10 photos to bring your profile to life",
                        "Photos give candidates a more authentic feel for your team and office, and suggest what the culture is like",
                        "Engaging visuals grab attention, highlight diversity and make you memorable in a competitive market",
                      ]}
                    />
                  )}
                  <ImageUploadField
                    id="photos"
                    value={form.values.photos ? form.values.photos : []}
                    onChange={photos => {
                      form.setFieldValue("photos", photos);
                    }}
                    category={ImageCategory.Photo}
                    nounSingular="your photo"
                    nounPlural="photos"
                    fileCountMax={10}
                    error={formatPhotosErrors(form)}
                    featureGranted={photosFeature.granted}
                  />
                  {photosFeature.granted && (
                    <Tipbox level="information">
                      <p>
                        Upload up to 10 photos to bring working at {data.name}{" "}
                        to life. Photos must be 5MB or less.
                      </p>
                    </Tipbox>
                  )}
                </Spacing>
              </MoreInfoFieldWrapper>
              <StyledFieldWrapper
                label="Days per week in office (not shown on remote roles)"
                advice="Leave the maximum blank if you only have a minimum number of office days."
                minError={form.errors.requiredOfficeDaysRange?.minDaysInOffice}
                maxError={form.errors.requiredOfficeDaysRange?.maxDaysInOffice}
              >
                {({ min, max }) => (
                  <FormikRange
                    minName="requiredOfficeDaysRange.minDaysInOffice"
                    maxName="requiredOfficeDaysRange.maxDaysInOffice"
                    min={min}
                    max={max}
                  />
                )}
              </StyledFieldWrapper>
              <InputField
                name="hqAddress"
                label="HQ address"
                value={form.values.hqAddress}
                onChange={form.handleChange("hqAddress")}
                onBlur={form.handleBlur("hqAddress")}
                error={
                  form.touched.hqAddress ? form.errors.hqAddress : undefined
                }
              />

              <FieldContainer>
                <Label>Our take</Label>
                <OttasTake data={data.marketBullets} />
              </FieldContainer>

              <Button
                type="submit"
                level="primary"
                disabled={form.isSubmitting}
              >
                {form.isSubmitting ? "Saving..." : "Save changes"}
              </Button>
            </Spacing>
          </Form>
        );
      }}
    </Formik>
  );
};
