import { FormikErrors } from "formik";
import { useEffect, useState } from "react";
import styled from "@xstyled/styled-components";

import { useJobEditFormikContext } from "../useHandlers";

import { SalaryVisibilityField } from "./Fields/Compensation/SalaryVisibilityField";
import {
  SalaryRangeField,
  AccessibilityProps,
} from "./Fields/Compensation/components/SalaryRangeField";
import { transformIncoming } from "./Fields/Compensation/utils";
import { AddOptionalButton } from "./Fields/Compensation/components/AddOptionalButton";

import { FieldWrapper, Fieldset, List, Spacing, Textarea } from "@otta/design";
import { Currency, JobFeature, JobFragment } from "@hire/schema";
import { useJobAuthorization } from "@hire/providers/authorization";
import { SalaryRange } from "@hire/util/salaries";
import { Loading } from "@otta/shared-components";
import { palette } from "@otta/design-tokens";

const StyledList = styled(List)`
  li {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const AdditionalCompensationBullets = () => (
  <StyledList color={palette.grayscale.shade600} type="bullet">
    <li key="outline-additional-compensation">
      Briefly outline equity grants, bonuses or location-specific salaries. For
      example, &apos;1% equity&apos; or &apos;New York salary only&apos;.
    </li>
    <li key="no-benefits-additional-compensation">
      Don&apos;t add benefits: your company profile, including benefits, will
      appear on every job you publish.
    </li>
  </StyledList>
);

export function Compensation({
  companyJob,
}: {
  companyJob?: JobFragment | null;
}) {
  const form = useJobEditFormikContext();
  const { granted: canViewSalary, loading: authLoading } = useJobAuthorization(
    JobFeature.ViewSalary
  );
  const isDraft = !companyJob;

  const showSalaryFields = canViewSalary || isDraft;

  const [showOTE, setShowOTE] = useState(!!form.values.oteRange);
  const [showAdditionalCompensation, setShowAdditionalCompensation] = useState(
    !!form.values.otherCompensation
  );
  const [currency, setCurrency] = useState<Currency>();

  const handleRemoveOTE = () => {
    form.setFieldValue("oteRange", undefined);
    setShowOTE(false);
  };

  const handleChange = (
    newValue: SalaryRange,
    onChangeCall: (newValue: SalaryRange) => void
  ) => {
    setCurrency(newValue.currency);
    onChangeCall(newValue);
  };

  useEffect(() => {
    setCurrency(
      form.values.oteRange?.currency ?? form.values.salaryRange.currency
    );
  }, [form.values.salaryRange.currency, form.values.oteRange?.currency]);

  const [oteSalaryRangeError, setOTESalaryRangeError] = useState<
    FormikErrors<SalaryRange<number>> | undefined
  >(undefined);

  useEffect(() => {
    setOTESalaryRangeError(
      form.errors.oteRange as FormikErrors<SalaryRange<number>> | undefined
    );
  }, [form.errors.oteRange]);

  if (!currency || (!isDraft && authLoading)) {
    return <Loading />;
  }

  const salaryTouched: boolean =
    (form.touched.salaryRange?.currency ?? false) ||
    (form.touched.salaryRange?.minAmount ?? false) ||
    (form.touched.salaryRange?.maxAmount ?? false);

  const oteTouched: boolean = form.touched.oteRange ?? false;

  return (
    <Fieldset legend="Salary">
      <FieldWrapper
        required
        label="Base salary"
        advice={
          showSalaryFields
            ? "We'll only show your job to candidates with matching salary expectations."
            : "You don't have permission to view or edit the salary for this job."
        }
        minError={
          salaryTouched ? form.errors.salaryRange?.minAmount : undefined
        }
        maxError={
          salaryTouched ? form.errors.salaryRange?.maxAmount : undefined
        }
      >
        {({
          min,
          max,
        }: {
          min?: AccessibilityProps;
          max?: AccessibilityProps;
        }) =>
          showSalaryFields ? (
            <SalaryRangeField
              onChange={newValue =>
                handleChange(newValue, (newValue: SalaryRange) => {
                  form.setFieldValue("salaryRange", newValue, false);
                })
              }
              onBlur={() =>
                form.setFieldTouched("salaryRange.minAmount", true, true)
              }
              value={transformIncoming(form.values.salaryRange, currency)}
              min={min}
              max={max}
            />
          ) : null
        }
      </FieldWrapper>
      {/* OTE salary */}
      {showSalaryFields && (
        <div>
          {showOTE ? (
            <FieldWrapper
              advice="Remember to include the base salary."
              label="On-target earnings (OTE)"
              minError={oteTouched ? oteSalaryRangeError?.minAmount : undefined}
              maxError={oteTouched ? oteSalaryRangeError?.maxAmount : undefined}
            >
              {({
                min,
                max,
              }: {
                min?: AccessibilityProps;
                max?: AccessibilityProps;
              }) => (
                <SalaryRangeField
                  onChange={newValue =>
                    handleChange(
                      newValue,
                      (newValue: SalaryRange | undefined) => {
                        form.setFieldValue("oteRange", newValue, false);
                        form.setFieldTouched("oteRange", true, false);
                      }
                    )
                  }
                  onRemove={handleRemoveOTE}
                  onBlur={() => form.setFieldTouched("oteRange", true, true)}
                  value={transformIncoming(form.values.oteRange, currency)}
                  min={min}
                  max={max}
                />
              )}
            </FieldWrapper>
          ) : (
            <FieldWrapper>
              {({ field }) => (
                <div>
                  <AddOptionalButton
                    onClick={() => setShowOTE(true)}
                    text="Add on-target earnings (OTE)"
                    {...field}
                  />
                </div>
              )}
            </FieldWrapper>
          )}
        </div>
      )}
      {/* Salary visbility */}
      <FieldWrapper>
        {({ field }) => (
          <SalaryVisibilityField
            hideSalary={!!form.values.hideSalary}
            externalId={companyJob?.externalId}
            handleFieldChange={form.setFieldValue}
            salaryVisibleToIds={form.values.salaryVisibleToIds}
            {...field}
          />
        )}
      </FieldWrapper>
      {/* Additional compensation */}
      {showAdditionalCompensation ? (
        <FieldWrapper
          label="Additional compensation"
          fieldError={form.errors.otherCompensation}
        >
          {({ field }) => (
            <Spacing size={-4}>
              <AdditionalCompensationBullets />
              <Textarea
                value={form.values.otherCompensation}
                onChange={e =>
                  form.handleChange("otherCompensation")(e.target.value)
                }
                {...field}
              />
            </Spacing>
          )}
        </FieldWrapper>
      ) : (
        <FieldWrapper>
          {({ field }) => (
            <div>
              <AddOptionalButton
                text="Add additional compensation"
                onClick={() => setShowAdditionalCompensation(true)}
                {...field}
              />
            </div>
          )}
        </FieldWrapper>
      )}
    </Fieldset>
  );
}
