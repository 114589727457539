import { styled } from "@xstyled/styled-components";

import {
  FlexContainer,
  ListItemWrapper,
  UpdateDeleteButtonSection,
} from "./styled-components";

import { palette } from "@otta/design-tokens";
import { DeleteButton } from "@hire/components/buttons/DeleteButton";
import { EditButton } from "@hire/components/buttons/EditButton";

const Overlay = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  height: 0;
  width: 100%;
  pointer-events: none;
  background-color: gray-50;
  opacity: 0.5;
  z-index: 1;

  &[aria-disabled="true"] {
    height: 100%;
  }
`;

interface ItemCardProps {
  testId?: string;

  children: React.ReactElement;
  itemIndex: number;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  disabled?: boolean;
}

export const ItemCard = ({
  itemIndex,
  testId,
  onEdit: handleEdit,
  onDelete: handleDelete,
  children,
  disabled = false,
}: ItemCardProps) => {
  return (
    <FlexContainer
      style={{
        position: "relative",
        pointerEvents: disabled ? "none" : "auto",
      }}
      data-testid={testId}
    >
      <Overlay aria-disabled={disabled} />
      <ListItemWrapper>{children}</ListItemWrapper>
      <UpdateDeleteButtonSection>
        <EditButton
          onClick={() => handleEdit(itemIndex)}
          color={palette.grayscale.shade600}
          hoverColor={palette.brand.black}
          size={2}
        />

        <DeleteButton
          onClick={() => handleDelete(itemIndex)}
          color={palette.brand.red}
          hoverColor={palette.extended.red.shade800}
        />
      </UpdateDeleteButtonSection>
    </FlexContainer>
  );
};
