import { CompanyAts } from "./schema";

export const ATSNames: Record<CompanyAts, string> = {
  [CompanyAts.Workable]: "Workable",
  [CompanyAts.Greenhouse]: "Greenhouse",
  [CompanyAts.Lever]: "Lever",
  [CompanyAts.Ashby]: "Ashby",
  [CompanyAts.Teamtailor]: "Teamtailor",
  [CompanyAts.Kombo]: "ATS",
};
