import { useQuery } from "@apollo/client";

import * as Banner from "@hire/components/Banner";
import { useRequiredParams } from "@hire/util/routing";
import { Text } from "@otta/design";
import {
  CompanyJobSlotExperimentPhase,
  CurrentUserDocument,
  Feature,
  StarterOrBasicDocument,
} from "@hire/schema";
import { useAuthorization } from "@hire/providers/authorization";
import { hireAppUser } from "@hire/util/user";

function CTA({
  jobExternalId,
  companySlug,
}: {
  jobExternalId: string;
  companySlug: string;
}): React.ReactElement {
  return (
    <Banner.CTA to={`/${companySlug}/jobs/unfreeze-job/${jobExternalId}`}>
      Publish this job
    </Banner.CTA>
  );
}

export function JobBanner({
  isPaused,
  jobExternalId,
}: {
  isPaused: boolean;
  jobExternalId: string;
}) {
  const { granted: canSelfServe, loading: authLoading } = useAuthorization(
    Feature.JobSlotCheckout
  );

  const { companySlug } = useRequiredParams(["companySlug"]);
  const { data, loading: userLoading } = useQuery(CurrentUserDocument);
  const { data: planData, loading: planLoading } = useQuery(
    StarterOrBasicDocument
  );

  const user = hireAppUser(data?.me);
  const userWithPlan = hireAppUser(planData?.me);
  const slots = user?.currentCompany?.jobSlotData;
  const isPayingCustomer =
    userWithPlan?.currentCompany?.isCurrentPayingCustomer;

  if (authLoading || userLoading || planLoading) {
    return <span data-testid="loading" />;
  }

  if (!slots || !slots.required) {
    return null;
  }

  switch (slots.experimentPhase) {
    case CompanyJobSlotExperimentPhase.Warning:
      return null;
    case CompanyJobSlotExperimentPhase.Active:
      if (isPaused && slots.paid === 0 && canSelfServe) {
        return (
          <Banner.Info name="paused">
            <Banner.Content>
              <Text bold>It&apos;s no longer free to post this job</Text>
              <Text size={0}>
                You&apos;re missing out on applications. Publish this job with
                our new pay-as-you-go pricing. Pay monthly, cancel anytime.
              </Text>
            </Banner.Content>
            <CTA companySlug={companySlug} jobExternalId={jobExternalId} />
          </Banner.Info>
        );
      } else if (isPaused && isPayingCustomer && !canSelfServe) {
        return (
          <Banner.Info name="paused">
            <Banner.Content>
              <Text bold>
                This job isn&apos;t covered by your current plan.
              </Text>
              <Text size={0}>
                You can publish this job by adding more countries to your plan.
                Speak to our team to learn more.
              </Text>
            </Banner.Content>
            <Banner.CTA to="https://employers.welcometothejungle.com/meetings/abby-scarborough/optimise-your-profile">
              Book a call
            </Banner.CTA>
          </Banner.Info>
        );
      }
      return null;

    default:
      return null;
  }
}
