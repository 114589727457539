import { parseISO, format } from "date-fns";
import { motion } from "framer-motion";
import styled, { css } from "@xstyled/styled-components";
import { useParams } from "react-router-dom";
import { useContext } from "react";

import { ConversationListItemNotification } from "./ConversationListItemNotification";

import { ManyInboxConversationFragment } from "@hire/schema";
import { Link } from "@hire/components/links";
import { TextWithElipsis } from "@hire/components/TextWithElipsis";
import { palette, modularScale } from "@otta/design-tokens";
import { Spacing, Text } from "@otta/design";
import { SensitiveInfoContext } from "@hire/providers/SensitiveInfo";

const MessageContainer = styled.div<{
  active: boolean;
}>`
  position: relative;
  border-radius: 4;
  background-color: beige-200;

  padding: sm;

  &:hover {
    background-color: beige-300;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: yellow-500 !important;
    `}
`;

const MessageName = styled(TextWithElipsis)`
  max-width: 80%;
`;

const MessageDate = styled(Text)`
  text-align: right;
  position: absolute;
  top: ${modularScale(-4)};
  right: ${modularScale(-4)};
`;

const MessagePreviewNotification = styled.div`
  display: flex;
  align-items: center;
`;

function recruiterReadStatus(
  recipient: ManyInboxConversationFragment["recipient"],
  jobApplication: ManyInboxConversationFragment["jobApplication"],
  latestMessage: ManyInboxConversationFragment["latestMessage"]
): boolean {
  if (jobApplication && jobApplication.accepted === null) {
    return false;
  } else if (latestMessage?.sender.id === recipient.id) {
    return latestMessage?.read;
  } else {
    return true;
  }
}

function candidateReadStatus(
  recipient: ManyInboxConversationFragment["recipient"],
  sourced: boolean,
  hasAcceptedRequest: boolean | null,
  jobApplication: ManyInboxConversationFragment["jobApplication"],
  latestMessage: ManyInboxConversationFragment["latestMessage"]
): boolean {
  if (sourced) {
    switch (hasAcceptedRequest) {
      case null:
        return false;
      case false:
        return true;
      case true:
        return latestMessage?.sender.id === recipient.id
          ? true
          : latestMessage?.read ?? false;
    }
  } else {
    return jobApplication?.accepted === null
      ? true
      : latestMessage?.read ?? false;
  }
}

interface ConversationListItemProps {
  conversation: ManyInboxConversationFragment;
  search: string;
  active: boolean;
}

export function ConversationListItem({
  conversation,
  active,
  search,
}: ConversationListItemProps): React.ReactElement {
  const { companySlug, jobId } = useParams();
  const { sensitiveFieldsHidden } = useContext(SensitiveInfoContext);

  const {
    conversationId,
    sourced,
    hasAcceptedRequest,
    jobApplication,
    recipient,
    latestMessage,
  } = conversation;

  const timestamp = latestMessage?.insertedAt
    ? latestMessage?.insertedAt
    : jobApplication?.lastActionAt;

  const sentByCompany = conversation?.latestMessage?.sender?.id
    ? conversation?.latestMessage?.sender?.id !== recipient.id
    : false;

  const candidateRead = candidateReadStatus(
    recipient,
    sourced,
    hasAcceptedRequest,
    jobApplication,
    latestMessage
  );

  const recruiterRead = recruiterReadStatus(
    recipient,
    jobApplication,
    latestMessage
  );

  const pathname = `/${companySlug}/jobs/${jobId}/inbox/${
    conversationId
      ? `candidates/${recipient.externalId}/conversation`
      : `candidates/${recipient.externalId}/profile`
  }`;

  const accepted = sourced
    ? hasAcceptedRequest
    : jobApplication
    ? jobApplication.accepted
    : true;

  const messageContent =
    jobApplication && jobApplication.accepted === null && jobApplication.applied
      ? sourced
        ? "Application from a sourced candidate"
        : "New application"
      : latestMessage?.content;

  const unreadOrActive = !recruiterRead || active;

  const textColor = unreadOrActive
    ? palette.brand.black
    : palette.grayscale.shade600;

  return (
    <motion.li
      style={{ listStyleType: "none" }}
      key={
        conversation.conversationId
          ? `conversation-${conversation.conversationId}`
          : `job-application-${conversation.jobApplicationId}`
      }
      initial={{ opacity: 0, x: -250 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -250 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      <Link
        data-testid="message-link"
        to={{ pathname, search }}
        underline={false}
      >
        <MessageContainer active={active}>
          {timestamp && (
            <MessageDate size={-1} color={textColor}>
              {format(parseISO(timestamp), "dd MMM")}
            </MessageDate>
          )}
          <Spacing size={-8}>
            <MessageName
              data-sensitive-field={sensitiveFieldsHidden}
              bold={!recruiterRead}
            >
              {recipient.firstName} {recipient.lastName}{" "}
            </MessageName>
            {messageContent && (
              <MessagePreviewNotification>
                <TextWithElipsis
                  size={-1}
                  color={textColor}
                  style={{
                    fontStyle: accepted === false ? "italic" : "normal",
                  }}
                >
                  {accepted === false
                    ? "This candidate declined the message request"
                    : messageContent}
                </TextWithElipsis>
                <ConversationListItemNotification
                  recruiterRead={recruiterRead}
                  read={candidateRead}
                  sentByCompany={sentByCompany}
                />
              </MessagePreviewNotification>
            )}
          </Spacing>
        </MessageContainer>
      </Link>
    </motion.li>
  );
}
