import { useEffect, useMemo } from "react";
import styled, { x, down, css } from "@xstyled/styled-components";

import { JobPreview } from "./JobPreview";
import { FunctionGroup } from "./FunctionGroup";
import { Filters } from "./Filters";
import { JobsListEmptyState } from "./JobsListEmptyState";
import { JobListMode, JobListStatus } from "./statusTypes";
import { DISAPPROVED_JOBS_DISPLAY_PERIOD, useJobsList } from "./useJobsList";

import { alphabeticallySortKeys } from "@hire/util/collections";
import { Spacing, Text, Tipbox } from "@otta/design";
import { JobPreviewFragment } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { ReloadableError } from "@hire/components/ReloadableError";

const JobList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: lg;
  align-items: stretch;
`;

const FormattedText = styled(Text)`
  max-width: 44rem;
  line-height: 1.5;
`;

const Container = styled.div`
  padding: 1.5rem;
`;

const Row = styled.div`
  justify-content: space-between;
  display: flex;

  ${down(
    "tablet",
    css`
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 1rem;
    `
  )}
`;

const SmallTipbox = styled(Tipbox)`
  max-width: fit-content;
`;

/**
 * Maybe a bit confusing but we show a simple tipbox if this is the `ONLY_PAUSED` list
 * otherwise we know this is the only list so show the full empty state message
 */
function EmptyState({
  mode,
  status,
}: {
  mode: JobListMode;
  status: JobListStatus;
}) {
  const emptyStatus = useMemo(() => {
    switch (status) {
      case JobListStatus.UNPUBLISHED:
        return "You don't have any unpublished jobs.";
      case JobListStatus.REVIEW:
        return "You don't have any jobs in review.";
      case JobListStatus.DRAFT:
        return "You don't have any draft jobs.";
      case JobListStatus.ARCHIVED:
        return "You don't have any archived jobs.";
      case JobListStatus.PUBLISHED:
        return "You don't have any published jobs.";
      case JobListStatus.DISAPPROVED:
        return `None of the jobs you've created in the last ${DISAPPROVED_JOBS_DISPLAY_PERIOD} days are disapproved.`;
    }
  }, [status]);

  if (
    status === JobListStatus.DISAPPROVED ||
    mode === JobListMode.ONLY_PAUSED_JOBS
  ) {
    return <SmallTipbox level="information">{emptyStatus}</SmallTipbox>;
  } else {
    return <JobsListEmptyState />;
  }
}

function Title({ status }: { status: JobListStatus }): React.ReactElement {
  switch (status) {
    case JobListStatus.PUBLISHED:
      return <>Published jobs</>;
    case JobListStatus.UNPUBLISHED:
      return <>Unpublished jobs</>;
    case JobListStatus.REVIEW:
      return <>Jobs in review</>;
    case JobListStatus.DRAFT:
      return <>Draft jobs</>;
    case JobListStatus.ARCHIVED:
      return <>Archived jobs</>;
    case JobListStatus.DISAPPROVED:
      return <>Disapproved jobs</>;
  }
}

function Subtitle({
  status,
}: {
  status: JobListStatus;
}): React.ReactElement | null {
  let text;

  switch (status) {
    case JobListStatus.UNPUBLISHED:
      text =
        "These jobs are available to publish on our platform. Unpublished jobs are not shown to candidates.";
      break;
    case JobListStatus.REVIEW:
      text =
        "Our in-house analysts are reviewing these jobs to ensure they match our supported locations and job functions. This may take up to 2 working days, but is usually quicker.";
      break;
    case JobListStatus.DRAFT:
      text =
        "This section stores the jobs you are working on, but have not yet published. You can return to finish and publish them whenever you're ready. Draft jobs are not shown to candidates.";
      break;
    case JobListStatus.ARCHIVED:
      text =
        "These are jobs that are no longer live on our platform. Archived jobs are not shown to candidates.";
      break;
    case JobListStatus.DISAPPROVED:
      text = (
        <>
          Jobs that you've created in the last {DISAPPROVED_JOBS_DISPLAY_PERIOD}{" "}
          days that have been disapproved. Disapproved jobs are not shown to
          candidates.{" "}
          <x.a
            color="inherit"
            href="https://support.welcometothejungle.com/en/articles/7178480-why-isn-t-my-job-live-on-welcome-to-the-jungle"
            target="_blank"
            rel="noopener noreferrer"
          >
            Find out more.
          </x.a>
        </>
      );
      break;
    default:
      return null;
  }
  return <FormattedText>{text}</FormattedText>;
}

export function FilterableJobList({
  onMissingSalary,
  actionButtons,
  mode,
}: {
  onMissingSalary: () => void;
  actionButtons?: React.ReactNode;
  mode: JobListMode;
}): React.ReactElement {
  const {
    updateSearchParams,
    loading,
    error,
    jobs = [],
    status,
    location,
    refetch,
    total,
  } = useJobsList(mode);

  useEffect(() => {
    if (
      jobs.some(j => !j.salaryRange && !j.oteSalaryRange) &&
      status === JobListStatus.PUBLISHED
    ) {
      onMissingSalary();
    }
  }, [jobs, status, onMissingSalary]);

  const jobsGroupedByFunction = useMemo(() => {
    const grouped = jobs.reduce<Record<string, JobPreviewFragment[]>>(
      (acc, job) => {
        const functionName = job.jobFunction?.value ?? "Unknown";

        if (!acc[functionName]) {
          acc[functionName] = [];
        }

        acc[functionName].push(job);

        return acc;
      },
      {}
    );

    return alphabeticallySortKeys(grouped);
  }, [jobs]);

  return loading || !status ? (
    <Loading />
  ) : (
    <Container data-testid="filterable">
      <Spacing size={2}>
        <Row>
          <Text as="h3" bold size={2}>
            <Title status={status} /> {total !== undefined && `(${total})`}
          </Text>
          {!!actionButtons && actionButtons}
        </Row>
        <Subtitle status={status} />
        <Row>
          <Filters
            mode={mode}
            status={status}
            location={location}
            updateSearchParams={updateSearchParams}
          />
        </Row>
        {Object.entries(jobsGroupedByFunction).map(([functionName, jobs]) => (
          <FunctionGroup
            key={functionName}
            name={functionName}
            total={jobs.length}
          >
            <JobList>
              {jobs.map(j => (
                <JobPreview status={status} key={j.id} {...j} />
              ))}
            </JobList>
          </FunctionGroup>
        ))}
        {!jobs.length && !error && !loading ? (
          <EmptyState status={status} mode={mode} />
        ) : null}
        {error && !loading ? (
          <ReloadableError action={() => refetch()} />
        ) : null}
      </Spacing>
    </Container>
  );
}
