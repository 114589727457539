import styled from "@xstyled/styled-components";

import { Radio } from "@hire/components/input/Radio";
import { FieldWrapper, Spacing, Text } from "@otta/design";

const FlexContainer = styled.div`
  display: flex;
`;

export function MarketingConsent({
  name,
  value,
  disabled,
  error,
  onChange,
}: {
  name: string;
  value: "true" | "false" | null;
  disabled?: boolean;
  error?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <FieldWrapper fieldError={error} required disabled={disabled}>
      {({ field }) => (
        <Spacing size={-4}>
          <Text size={-1} bold>
            Want to receive tips on how to hire better?
          </Text>
          <Text size={-1}>
            We&apos;ll send you emails relating to recruitment and updates about
            new features. You can unsubscribe at any time.
          </Text>
          <Spacing size={-5}>
            <FlexContainer>
              <Radio
                {...field}
                label="Yes"
                size={-1}
                value="true"
                checked={value === "true"}
                name={name}
                onChange={onChange}
              />
              <Radio
                {...field}
                label="No"
                size={-1}
                value="false"
                checked={value === "false"}
                name={name}
                onChange={onChange}
              />
            </FlexContainer>
          </Spacing>
        </Spacing>
      )}
    </FieldWrapper>
  );
}
