import styled, { x } from "@xstyled/styled-components";

import { useJobEditFormikContext } from "../useHandlers";
import { IOption } from "../types";

import {
  Button,
  Fieldset,
  FieldWrapper,
  SelectField,
  Text,
  WttjSymbol,
} from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { JobFragment } from "@hire/schema";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Icon } from "@otta/icons";

const SelectFieldContainer = styled.div`
  max-width: ${pxToRem(400)};
`;

function EbTag({ isUpsell }: { isUpsell?: boolean }) {
  return (
    <x.div
      backgroundColor="yellow-500"
      display="flex"
      alignItems="center"
      gap="xs"
      borderRadius="md"
      px="sm"
      py="xs"
    >
      {isUpsell && <Icon icon="locked" size={0} />}
      {!isUpsell && (
        <x.div display="flex" alignItems="center" w={16} h={16}>
          <WttjSymbol />
        </x.div>
      )}
      <Text bold size={-1}>
        Employer branding feature
      </Text>
    </x.div>
  );
}

export function Team({
  teamOptions,
}: {
  teamOptions: IOption[] | [];
  companyJob?: JobFragment | null;
}) {
  const hasTeams = teamOptions.length > 0;

  const form = useJobEditFormikContext();

  const handleChange = ({ value, label }: IOption) => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter selected EB team",
      value: label,
    });

    form.setValues({
      ...form.values,
      companyTeamId: value,
    });
  };

  return (
    <Fieldset
      legend="Team"
      advice="Every team will be listed in alphabetical order on this job. If you select a team, it will appear first."
      tag={<EbTag />}
    >
      <FieldWrapper>
        {() => (
          <SelectFieldContainer data-testid="select-team">
            <SelectField
              name="team"
              value={teamOptions.find(
                option => form.values.companyTeamId === option.value
              )}
              options={teamOptions}
              placeholder={hasTeams ? "Select a team..." : "No teams found"}
              isDisabled={!hasTeams}
              onChange={e => e && handleChange(e)}
            />
          </SelectFieldContainer>
        )}
      </FieldWrapper>
    </Fieldset>
  );
}

export function TeamUpsell({ isProCompany }: { isProCompany: boolean }) {
  return (
    <Fieldset legend="Team" tag={<EbTag isUpsell />}>
      <x.div
        display="flex"
        gap="xl"
        p="lg"
        flexDirection={{ _: "column", tablet: "row-reverse" }}
        borderRadius="md"
        border="1px solid"
        borderColor="gray-200"
      >
        <x.div
          display="flex"
          gap="lg"
          flexDirection="column"
          minWidth={{ tablet: 256 }}
          maxW={{ tablet: "50%" }}
          flexShrink={0}
        >
          <Text bold>Unlock employer branding</Text>
          <x.ul
            display="flex"
            flexDirection="column"
            gap="lg"
            listStyleType="none"
          >
            <x.li display="flex" alignItems="center" gap="sm">
              <Icon icon="checkmark" />
              <Text size={-1}>
                Stand out to candidates with enhanced profiles and job cards
              </Text>
            </x.li>
            <x.li display="flex" alignItems="center" gap="sm">
              <Icon icon="checkmark" />
              <Text size={-1}>
                Showcase your team and culture with dynamic photos and videos
                from our expert production team
              </Text>
            </x.li>
          </x.ul>
        </x.div>

        <x.div
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap="sm"
        >
          <x.img
            display={{ _: "none", tablet: "block" }}
            width="100%"
            src="https://static.otta.com/hire/job_eb_upsell.webp"
            alt="Upsell screenshots"
          />
          {isProCompany ? (
            <Button
              as="a"
              level="primary"
              size="small"
              href="https://meetings.hubspot.com/yoyin/book-call-eb?utm_source=hire_app&utm_medium=job_team&utm_campaign=upsell"
              target="_blank"
              rel="noopener noreferrer"
              data-analytics-id="job-team-upsell-find-out-more"
            >
              Find out more
            </Button>
          ) : (
            <Button
              as="a"
              level="primary"
              size="small"
              href="/book-a-call?utm_source=hire_app&utm_medium=job_team&utm_campaign=upsell"
              data-analytics-id="job-team-upsell-find-out-more"
            >
              Find out more
            </Button>
          )}
        </x.div>
      </x.div>
    </Fieldset>
  );
}
