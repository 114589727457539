import { useQuery } from "@apollo/client";

import { CertifiedOverview } from "./CertifiedOverview";
import { JobOverview } from "./JobOverview";

import { DashboardDocument, Feature } from "@hire/schema";
import { hireAppUser } from "@hire/util/user";
import { Loading } from "@otta/shared-components";
import { Spacing } from "@otta/design";
import { RemovedJobsExplanation } from "@hire/components/RemovedJobsModal";
import { useAuthorizations } from "@hire/providers/authorization";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";

export const Dashboard = () => {
  const { data, loading } = useQuery(DashboardDocument, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const currentUser = hireAppUser(data?.me);

  const { features } = useAuthorizations([Feature.Home, Feature.Scraped]);

  if (loading) {
    return <Loading />;
  }

  const currentCompany = currentUser?.currentCompany;

  if (!currentCompany) {
    return null;
  }

  return (
    <>
      <JobOverview />
      <ResponsiveCenteredContainer maxWidth={1400} align="left">
        <Spacing size={1}>
          <CertifiedOverview currentCompany={currentCompany} />
        </Spacing>

        <RemovedJobsExplanation
          enabled={features.get(Feature.Home)?.message === "paused"}
          campaign="dashboard"
        />
      </ResponsiveCenteredContainer>
    </>
  );
};
