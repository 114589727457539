import { differenceInDays, parseISO } from "date-fns";

import { CandidateCardFragment } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

interface CandidateIsNewUserBadgeProps {
  candidate: CandidateCardFragment;
}

export function CandidateIsNewUserBadge({
  candidate,
}: CandidateIsNewUserBadgeProps): React.ReactElement | null {
  const candidateIsNewUser =
    differenceInDays(new Date(), parseISO(candidate.insertedAt)) <= 14;

  return candidateIsNewUser ? (
    <RoundedTag
      icon="new-to-otta"
      content="New user account"
      hoverText="This account is under 14 days old"
      color={palette.brand.green}
    />
  ) : null;
}
