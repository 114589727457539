import styled, { css, down } from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";
import { PaperplaneIcon, PencilIcon, CelebrateIcon } from "@welcome-ui/icons";

import { Card, Heading, Text, responsive } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { LogoHeader, useProgress } from "@hire/components/layout/QuizLayout";
import { CurrentUserDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";

const PageWrapper = styled.div`
  display: flex;
  padding: 80 xxl;
  flex-direction: column;
  align-items: center;
  gap: 80;

  ${down(
    "tablet",
    css`
      padding: 40 lg;
      gap: 40;
    `
  )}
`;

const Main = styled.main`
  background-color: beige-200;
  min-height: 100vh;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: xl;
  max-width: ${pxToRem(1376)};

  ${down(
    "tablet",
    css`
      grid-template-columns: 1fr;
      gap: lg;
    `
  )}
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4xl;
  padding: 40 xl 4xl;
  border-radius: lg;
  text-align: center;

  ${down(
    "tablet",
    css`
      gap: lg;
      padding: xl lg xxl;
    `
  )}
`;

const StatusTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: lg;
  align-self: stretch;
`;

const CardTitle = styled(Heading)`
  ${responsive`font-size: ${responsive.modularScale({
    mobile: 1,
    desktop: 3,
  })};`}
`;

export function Success() {
  useProgress(0);
  const { data, loading } = useQuery(CurrentUserDocument);

  if (loading) {
    return <Loading />;
  }

  if (
    !data?.me ||
    data?.me?.__typename !== "CurrentCompanyRecruiter" ||
    !data.me.currentCompany
  ) {
    return <Navigate to="/quiz" />;
  }

  return (
    <Main>
      <LogoHeader />
      <PageWrapper>
        <Heading size={5}>You&apos;re all set to get started!</Heading>

        <CardContainer>
          <StyledCard>
            <PaperplaneIcon w={{ _: 80, tablet: 100 }} />
            <StatusTextContainer>
              <CardTitle>1. Verify your email</CardTitle>
              <Text>
                Click the link in the email we sent you to be able to sign in.
              </Text>
            </StatusTextContainer>
          </StyledCard>
          <StyledCard>
            <PencilIcon w={{ _: 80, tablet: 100 }} />
            <StatusTextContainer>
              <CardTitle>2. Update your profile</CardTitle>
              <Text>
                More detailed profiles perform better. Make yours stand out by
                adding benefits and other information.
              </Text>
            </StatusTextContainer>
          </StyledCard>
          <StyledCard>
            <CelebrateIcon w={{ _: 80, tablet: 100 }} />
            <StatusTextContainer>
              <CardTitle>3. Post a job</CardTitle>
              <Text>
                If you don&apos;t use an ATS, you can post jobs and view
                applications on Welcome to the Jungle.
              </Text>
            </StatusTextContainer>
          </StyledCard>
        </CardContainer>
      </PageWrapper>
    </Main>
  );
}
