import { Link } from "@hire/components/links";
import { Checkbox, FieldWrapper, Spacing, Text } from "@otta/design";

export function TermsConditionsConsent({
  name,
  disabled,
  error,
  checked,
  onChange,
}: {
  name: string;
  disabled?: boolean;
  error?: string;
  checked: boolean;
  onChange(value: boolean): void;
}) {
  return (
    <FieldWrapper fieldError={error} disabled={disabled} required>
      {({ field }) => (
        <Spacing size={-5}>
          <Checkbox
            {...field}
            label={
              <Text as="span" size={-1}>
                I've read and agree to the{" "}
                <Link to="https://otta.com/privacy-policy" newTab>
                  Privacy Policy
                </Link>{" "}
                and{" "}
                <Link
                  to="https://otta.com/terms-and-conditions/employers"
                  newTab
                >
                  Terms and conditions
                </Link>{" "}
                for employers.
              </Text>
            }
            name={name}
            value={name}
            checked={checked}
            onChange={onChange}
          />
        </Spacing>
      )}
    </FieldWrapper>
  );
}
