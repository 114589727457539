import styled from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";

const NewMessageSymbol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${modularScale(-1)};
  font-size: ${modularScale(-1)};
  color: orange-400;
`;

const DeliveredSymbol = styled(Icon)<{ $read: boolean }>`
  display: inline-block;
  margin-left: 6;
  stroke: ${({ $read }) =>
    $read ? palette.brand.green : palette.grayscale.shade600};
  height: ${modularScale(-2)};
  width: auto;
`;

const StyledTooltipWithIcon = styled(TooltipWithIcon)`
  padding: sm;
`;

interface ConversationListItemNotificationProps {
  recruiterRead: boolean | undefined | null;
  read?: boolean;
  sentByCompany?: boolean;
}

export function ConversationListItemNotification({
  recruiterRead,
  read,
  sentByCompany,
}: ConversationListItemNotificationProps): React.ReactElement | null {
  if (!recruiterRead) {
    return (
      <StyledTooltipWithIcon
        content="Awaiting your response"
        icon={
          <NewMessageSymbol data-testid="new-message-symbol">
            ●
          </NewMessageSymbol>
        }
      />
    );
  }
  if (!sentByCompany) {
    return null;
  }
  return (
    <StyledTooltipWithIcon
      content={read ? "Read" : "Sent"}
      icon={
        <DeliveredSymbol
          icon="checkmark-double"
          data-testid="delivered-symbol"
          $read={!!read}
        />
      }
    />
  );
}
