import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import { FocusEvent } from "react";
import styled from "@xstyled/styled-components";

import { FieldErrors } from "../FieldErrors";

import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Button, Spacing } from "@otta/design";
import { DeleteButton } from "@hire/components/buttons/DeleteButton";
import { Textarea } from "@hire/components/input/Textarea";

const BulletContainer = styled.div`
  margin-bottom: lg;
`;

const BulletRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: stretch;
  gap: 10;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

interface Bullet {
  value?: string;
  order?: number;
}

interface BulletsProps {
  values: Bullet[];
  onChange: (bullets: Bullet[]) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  fieldName: string;
  placeholders: string[];
  nounSingular?: string | undefined;
  errors?: string | (string | undefined)[];
  featureGranted?: boolean;
}

export function Bullets({
  values,
  onChange,
  onBlur,
  fieldName,
  placeholders,
  nounSingular = "item",
  errors,
  featureGranted = true,
}: BulletsProps) {
  const handleChange = (index: number, bullet: Bullet) => {
    const newValue = [...values];
    newValue[index] = bullet;
    onChange(newValue);
  };

  const handleDelete = (index: number) => {
    const newValue = [...values];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  const handleAdd = () => {
    const newValue = [...values, { value: undefined, order: undefined }];
    onChange(newValue);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newValue = [...values];
    const [reorderedItem] = newValue.splice(result.source.index, 1);
    newValue.splice(result.destination.index, 0, reorderedItem);
    onChange(newValue);
  };

  return (
    <Spacing size={2}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" isDropDisabled={!featureGranted}>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {values.map((v, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  index={index}
                  isDragDisabled={!featureGranted}
                >
                  {provided => (
                    <BulletContainer>
                      <BulletRow
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Icon icon="grip-lines" size={1} />
                        <Textarea
                          key={index}
                          name={`${fieldName}-${index}`}
                          value={v.value}
                          data-testid={fieldName}
                          placeholder={
                            placeholders.length > 0
                              ? placeholders[index % placeholders.length]
                              : ""
                          }
                          onChange={e =>
                            handleChange(index, {
                              order: v.order,
                              value: e.target.value,
                            })
                          }
                          onBlur={onBlur}
                          disabled={!featureGranted}
                        />
                        <DeleteButton
                          onClick={() => handleDelete(index)}
                          color={palette.brand.red}
                          hoverColor={palette.extended.red.shade800}
                          label={`Delete ${nounSingular}`}
                          disabled={!featureGranted}
                        />
                      </BulletRow>
                      <FieldErrors
                        errors={
                          errors && Array.isArray(errors)
                            ? errors[index]
                            : undefined
                        }
                      />
                    </BulletContainer>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {errors && typeof errors === "string" && <FieldErrors errors={errors} />}
      <ButtonContainer>
        <Button
          level="secondary"
          size="regular"
          onClick={e => {
            e.preventDefault();
            handleAdd();
          }}
          disabled={!featureGranted}
        >
          Add {nounSingular}
        </Button>
      </ButtonContainer>
    </Spacing>
  );
}
