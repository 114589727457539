import { styled } from "@xstyled/styled-components";

import { Upsell } from "../CompanyDetails/Upsell";

import { Spacing, Text } from "@otta/design";
import { CopyTextField } from "@hire/components/CopyTextField";
import { RoundedTag } from "@hire/components/tags/RoundedTag";
import { RectangleTag } from "@hire/components/tags/RectangleTag";
import { palette } from "@otta/design-tokens";

const TextWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: sm;
`;

export const PageHeading = ({
  urlSafeName,
  featureGranted = true,
  showUpsell = false,
}: {
  urlSafeName: string;
  featureGranted?: boolean;
  showUpsell?: boolean;
}) => {
  return (
    <Spacing>
      <TextWrapper>
        <Text size={2} bold>
          Employee endorsements
        </Text>
        {!featureGranted && (
          <RectangleTag
            icon={"locked"}
            color={palette.brand.yellow}
            iconColor={palette.brand.black}
            textColor={palette.brand.black}
            content={"Upgrade"}
            testId="unlock-feature-badge"
          />
        )}
      </TextWrapper>
      {showUpsell && (
        <Upsell
          title="Add authentic endorsements from your team"
          listItems={[
            "Endorsements are a positive space for existing employees to share what makes your company great, in their own words",
            "Personal and authentic employee feedback help candidates apply with confidence",
          ]}
        />
      )}
      {featureGranted ? (
        <Text>
          Endorsements are a positive space for existing employees to share what
          makes your company great.
        </Text>
      ) : (
        <Text align="left">
          This content is not being shown to candidates. Upgrade to unlock.
        </Text>
      )}

      {featureGranted && (
        <CopyTextField
          value={`${
            import.meta.env.VITE_SEARCH_APP_HOST
          }/companies/${urlSafeName}/write-an-endorsement`}
          label="Share this link for employees to add endorsements:"
          dataId="copy-endorsements-link"
        />
      )}

      {featureGranted && (
        <RoundedTag
          icon="clock"
          content={
            <Text size={-1}>
              Endorsements are typically published&nbsp;
              <strong>2 business days</strong>
              &nbsp;after submission.
            </Text>
          }
          center
        />
      )}
    </Spacing>
  );
};
