import { useLocation, useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { ConversationListItem } from "./ConversationListItem";

import { AllConversationsQuery } from "@hire/schema";
import { HireAppUser } from "@hire/util/user";
import { Middle, VerticalSpacing } from "@otta/design";
import { Link } from "@hire/components/links";
import { FetchMore } from "@hire/components/Fetchmore";

interface ConversationsListProps {
  conversations: HireAppUser<
    NonNullable<AllConversationsQuery["me"]>
  >["companyConversations"];
  fetchMore: () => Promise<void>;
  removeFilters: () => void;
}

export function ConversationsList({
  conversations,
  fetchMore,
  removeFilters,
}: ConversationsListProps): React.ReactElement {
  const { search } = useLocation();
  const { candidateId } = useParams();

  if (conversations.length) {
    return (
      <VerticalSpacing size={-4}>
        <AnimatePresence initial={false}>
          {conversations.map(c => (
            <ConversationListItem
              key={c.recipient.id}
              conversation={c}
              search={search}
              active={c.recipient.externalId === candidateId}
            />
          ))}
        </AnimatePresence>
        <FetchMore fetchMore={fetchMore} />
      </VerticalSpacing>
    );
  }

  return <NoConversations removeFilters={removeFilters} />;
}

function NoConversations({ removeFilters }: { removeFilters: () => void }) {
  return (
    <Middle>
      <div>No candidates match your filters</div>
      <Link
        onClick={e => {
          e.preventDefault();
          removeFilters();
        }}
        to="#"
      >
        Remove filters
      </Link>
    </Middle>
  );
}
