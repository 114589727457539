import styled from "@xstyled/styled-components";
import { useApolloClient } from "@apollo/client";
import { useMemo } from "react";

import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { Link } from "@hire/components/links/Link";
import { Button, Spacing, Text } from "@otta/design";
import { useCompanyIsActive } from "@hire/hooks/useCompanyIsActive";
import { Loading } from "@otta/shared-components";
import { useCompleteCheckoutSession } from "@hire/hooks/useCompleteCheckoutSession";

const PageWrapper = styled(ResponsiveCenteredContainer)`
  text-align: left;
  max-width: 100%;
  padding: 40;
`;

export function Confirmation(): React.ReactElement {
  const { active: isActive } = useCompanyIsActive();
  const client = useApolloClient();
  useCompleteCheckoutSession();

  useMemo(() => {
    if (isActive !== null) {
      client.resetStore();
    }
  }, [isActive, client]);

  if (isActive === null) {
    return <Loading />;
  }

  return (
    <PageWrapper>
      <Spacing size={5}>
        <Text size={3} bold>
          {isActive ? (
            <>Thanks, you now have more job slots</>
          ) : (
            <>Thanks, your profile will be ready within 1 working day.</>
          )}
        </Text>
        <Spacing size={2}>
          {isActive ? (
            <Spacing>
              <Text>We have emailed you a receipt.</Text>
              <Text>
                You can view and update your payments from the &apos;Manage
                billing&apos; link in Settings.
              </Text>
            </Spacing>
          ) : (
            <Text>
              We have emailed you a receipt. You will receive another email when
              your profile is set up.
            </Text>
          )}
          {isActive && (
            <Button
              style={{ textDecoration: "none" }}
              level="primary"
              as={Link}
              to="../../jobs"
            >
              Back to jobs
            </Button>
          )}
        </Spacing>
      </Spacing>
    </PageWrapper>
  );
}
