import styled from "@xstyled/styled-components";
import { useNavigate } from "react-router-dom";

import { Onboarding } from "../Auth/Onboarding";

import { useRequireLoginState } from "./useRequireLoginState";
import { BreadCrumbs } from "./Breadcrumbs";

import { pxToRem } from "@otta/design-tokens";
import { useProgress } from "@hire/components/layout/QuizLayout";
import { Loading } from "@otta/shared-components";
import { Text } from "@otta/design";

const PageContainer = styled.div`
  width: 100%;
  margin: 0 0 1rem 0;
`;

export function Signup(): React.ReactElement | null {
  useProgress(0);

  const navigate = useNavigate();

  const { loading } = useRequireLoginState({
    state: "logged-out",
    redirect: "/quiz/success",
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <PageContainer>
        <BreadCrumbs currentBreadcrumb="Account" />
        <Onboarding
          title="Create an account"
          onError={(error: string) => {
            if (error.includes("short_description")) {
              navigate("/quiz/company", { state: { error: error } });
            }
          }}
        />
        <Text size={-1} style={{ margin: `0 ${pxToRem(24)}` }}>
          <Text size={-1} inline bold>
            No commitment.
          </Text>{" "}
          Pay monthly, cancel online anytime.
        </Text>
      </PageContainer>
    );
  }
}
