import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";

import {
  CatalogueProductId,
  CreateCheckoutSessionDocument,
} from "@hire/schema";

export function usePaymentCheckoutLink(returnPath: string): {
  callback: () => void;
  loading: boolean;
} {
  const [createSession, { loading }] = useMutation(
    CreateCheckoutSessionDocument
  );

  const callback = useCallback(async () => {
    const { data } = await createSession({
      variables: {
        productId: CatalogueProductId.JobSlotMonthly,
        returnPath,
        quantity: 1,
      },
    });

    const link = data?.createCompanySubscriptionCheckoutSession?.url;

    if (link) {
      window.location.href = link;
    }
  }, [createSession, returnPath]);

  return useMemo(
    () => ({
      callback,
      loading,
    }),
    [callback, loading]
  );
}
